import React from 'react';
import { faTrash, faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { TextInput } from '../../../../components/textInput';
import { Button } from '../../../../components/button';
import { get } from 'lodash';
import './index.scss';

export const ChildrenEditor = (props) => {
  const { loading, children, addChild, updateChild, deleteChild } = props;

  return (
    <ul className={'children-editor'}>
      <h2>Sub-achievements</h2>

      {children.map((child) => {
        const canDelete = !child.existing;

        return (
          <li key={child.id}>
            <TextInput
              classString={'child-name'}
              label={'Name'}
              onChange={(e) =>
                updateChild(child.id, {
                  name: get(e, ['target', 'value'], ''),
                })
              }
              loading={loading}
              value={child.name}
            />
            <TextInput
              classString={'child-quantity'}
              label={'Quantity'}
              maskType={'number'}
              onChange={(e) =>
                updateChild(child.id, {
                  quantity: get(e, ['target', 'value'], ''),
                })
              }
              loading={loading}
              value={child.quantity}
            />
            <div className={'child-delete'}>
              <Button
                text={'Delete'}
                type={'button'}
                icon={faTrash}
                loading={loading}
                disabled={!canDelete}
                buttonStyle={canDelete ? 'delete' : ''}
                onClick={() => deleteChild(child.id)}
                aria-label={'Delete this sub-achievement'}
              />
            </div>
          </li>
        );
      })}

      <Button
        text={'Add sub-achievement'}
        type={'button'}
        buttonStyle={'add-child'}
        icon={faPlusCircle}
        loading={loading}
        onClick={addChild}
        aria-label={'Add sub-achievement'}
      />
    </ul>
  );
};
