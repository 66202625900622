import React from 'react';
import { useFormik } from 'formik';
import { TextInput } from '../../../components/textInput';
import { Button } from '../../../components/button';
import { faGameBoard } from '@fortawesome/pro-regular-svg-icons';
import { Checkbox } from '../../../components/checkbox';
import * as Yup from 'yup';
import { get, isEmpty } from 'lodash';

export default (props) => {
  const { editing, game, loading, onSubmit } = props;

  const wasPublic = get(game, ['isPublic'], false);

  const formik = useFormik({
    initialValues: {
      name: get(game, ['name'], ''),
      publicGame: wasPublic,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, 'Must be at least 3 characters')
        .required('Required'),
      publicGame: Yup.boolean(),
    }),
    onSubmit: (values) => onSubmit(values),
  });

  const formClass = loading ? 'disabled' : '';
  const buttonMessage = editing ? 'Update Game' : 'Add New Game';

  return (
    <form className={formClass} onSubmit={formik.handleSubmit}>
      <TextInput
        id={'name'}
        label={'Game name'}
        icon={faGameBoard}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        loading={loading}
        value={formik.values.name}
        error={formik.touched.name ? formik.errors.name : null}
        aria-invalid={Boolean(formik.touched.name && formik.errors.name)}
      />

      {!wasPublic && (
        <Checkbox
          id={'publicGame'}
          label={
            'PUBLIC (lets other users add and track achievements for this game)'
          }
          onChange={formik.handleChange}
          checked={formik.values.publicGame}
        />
      )}

      <Button
        text={buttonMessage}
        type={'submit'}
        loading={loading}
        aria-invalid={!isEmpty(formik.errors)}
        aria-label={
          isEmpty(formik.errors)
            ? buttonMessage
            : 'Please fix the errors to ' + buttonMessage
        }
      />
    </form>
  );
};
