import { SET, RESET, UPDATE_FIELDS } from './constants';

const initialState = {};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET:
      return action.newUser;
    case RESET:
      return initialState;
    case UPDATE_FIELDS:
      return {
        ...state,
        ...action.fields,
      };
    default:
      return state;
  }
};
export default usersReducer;
