import React from 'react';
import Select from 'react-select';
import './index.scss';

const customStyles = {
  control: (styles) => ({
    ...styles,
    color: '#FFF',
    backgroundColor: 'transparent',
    border: 'none',
    borderBottom: '1px solid #bfbfbf',
    cursor: 'pointer',
  }),
  menu: (styles) => ({
    ...styles,
    border: 'none',
    backgroundColor: 'transparent',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      border: '1px solid #bfbfbf', // $gray
      backgroundColor: isFocused ? '#444' : '#222',
      color: '#f2e9d8', // $ivory
      cursor: isDisabled ? 'not-allowed' : 'pointer',
    };
  },
};

export class SelectInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      localValue: '',
      focused: false,
    };
  }

  handleFocus = () => {
    this.setState({ focused: true });
  };

  handleBlur = (e) => {
    const { onBlur } = this.props;
    this.setState({ focused: false });
    if (onBlur) {
      onBlur(e);
    }
  };

  handleLocalChange = (e) => {
    this.setState({ localValue: e.value });
  };

  handleFormikChange = (e) => {
    const { id, onChange } = this.props;
    onChange(id, e.value, true);
  };

  render() {
    const { id, label, value, error, onChange, loading, options } = this.props;
    const { focused, localValue } = this.state;

    const wrapperClass = error
      ? 'select-input-wrapper error'
      : 'select-input-wrapper';
    const inputId = id;
    const changeAction = onChange
      ? this.handleFormikChange
      : this.handleLocalChange;
    const inputValue = onChange ? value : localValue;
    const className = inputValue || focused ? 'animated above' : 'animated';
    const ariaLabel = error ? label + '. ' + error : label;
    const maskedProps = {
      'aria-invalid': this.props['aria-invalid'],
    };

    return (
      <div className={wrapperClass}>
        <label htmlFor={inputId} className={className}>
          {label}
        </label>
        <Select
          {...maskedProps}
          id={inputId}
          name={inputId}
          defaultValue={inputValue}
          value={inputValue}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={changeAction}
          options={options}
          aria-label={ariaLabel}
          isDisabled={loading}
          styles={customStyles}
        />
        <p className={'validation-error'} role={'alert'}>
          {error}
        </p>
      </div>
    );
  }
}
