import { get } from 'lodash';
import ReactGA from 'react-ga';
import { CAT_ERROR, ACT_NAVIGATE } from '../analyticsConstants';

// programmatically navigate between react-router routes
export default (props, path) => {
  if (get(props, ['history'], null)) {
    props.history.push(path);
  } else {
    // report error to analytics
    ReactGA.event({
      category: CAT_ERROR,
      action: ACT_NAVIGATE,
      label: path,
    });
  }
};
