import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { CAT_SECURITY, ACT_SUBMIT_FORM } from '../../../analyticsConstants';
import coreApi from '../../../utils/coreApi';
import { withRouter } from 'react-router-dom';
import navigate from '../../../utils/navigate';
import LoginFormik from './form';
// import { Button } from '../../../components/button';
import { ConfirmationMessage } from '../../../components/confirmationMessage';
import { setUserAccessKey } from '../../../modules/userAccessKey/actions';
import { setUserAccessKeyTemp } from '../../../modules/userAccessKeyTemp/actions';
import { setUser } from '../../../modules/user/actions';
import { SUCCESS, ERROR } from '../../../constants';
import './index.scss';

class LoginForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      messageType: ERROR,
      message: '',
    };
  }

  handleLoginAttempt = (values) => {
    const { username, password, rememberMe } = values;
    const {
      // intent,
      setUserAccessKeyTemp,
      setUserAccessKey,
      setUser,
    } = this.props;
    const { loading } = this.state;

    if (!loading) {
      this.setState({ loading: true });
      const that = this;

      coreApi
        .post(
          '/auth/login',
          {
            username: username,
            password,
          },
          false
        )
        .then((results) => {
          const { accessToken, userDetails } = results;

          // display success message
          that.setState({
            messageType: SUCCESS,
            message: 'Login successful!',
          });

          // dispatch analytics
          ReactGA.event({
            category: CAT_SECURITY,
            action: ACT_SUBMIT_FORM,
            label: 'login',
          });

          // save accessKey
          if (rememberMe) {
            setUserAccessKey(accessToken);
          } else {
            setUserAccessKeyTemp(accessToken);
          }

          // save user details
          setUser(userDetails);

          // navigate to the dashboard (or original intent)
          setTimeout(function () {
            // const redirectTo = intent ? '/' + intent : '/'; // TODO: change where login redirects to
            const redirectTo = '/';
            navigate(that.props, redirectTo);
          }, 750);
        })
        .catch((error) => {
          // dispatch analytics
          ReactGA.event({
            category: CAT_SECURITY,
            action: ACT_SUBMIT_FORM,
            label: 'loginFailed',
          });

          // display error message
          setTimeout(function () {
            that.setState({
              loading: false,
              message: 'Login was unsuccessful, please try again.',
            });
          }, 1000);
        });
    }
  };

  gotoRegister = () => {
    navigate(this.props, '/register');
  };

  render() {
    const { loading, messageType, message } = this.state;

    return (
      <>
        <h1>Login to your account</h1>
        <p className={'instructions'}>
          First time?{' '}
          <Link id={'register-link'} to={'/register'} className={'animated'}>
            Click here to setup an anonymous account.
          </Link>
        </p>
        {message && (
          <ConfirmationMessage type={messageType} message={message} />
        )}
        <LoginFormik onSubmit={this.handleLoginAttempt} loading={loading} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  template: state.template,
});
const reduxActions = {
  setUserAccessKey,
  setUserAccessKeyTemp,
  setUser,
};
export default connect(mapStateToProps, reduxActions)(withRouter(LoginForm));
