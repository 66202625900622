import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { INFO, SUCCESS, ERROR } from '../../constants';
import './index.scss';

export const ConfirmationMessage = (props) => {
  const { message, type } = props;

  const classString = classMappings[type];
  const icon = iconMappings[type];

  return (
    <p className={classString}>
      <FontAwesomeIcon icon={icon} />
      {message}
    </p>
  );
};

const classMappings = {
  [INFO]: 'confirmation-message',
  [SUCCESS]: 'confirmation-message success',
  [ERROR]: 'confirmation-message error',
};
const iconMappings = {
  [INFO]: faInfoCircle,
  [SUCCESS]: faCheckCircle,
  [ERROR]: faExclamationCircle,
};
