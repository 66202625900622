import upsertArray from '../../utils/upsertArray';
import {
  UPSERT,
  CREATE,
  UPDATE,
  UPDATE_CHILD,
  DELETE,
  RESET,
  REPLACE_ALL,
} from './constants';
import { find, get } from 'lodash';

const initialState = {
  achievements: [],
  moreReady: false,
  lastUpdated: '',
};

const achievementsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPSERT:
      let upsertedAchievements = state.achievements;
      action.achievements.forEach((achievement) => {
        upsertedAchievements = upsertArray(
          upsertedAchievements,
          achievement,
          ['details', 'id'],
          (elem) => ({
            ...achievement,
          })
        );
      });
      return {
        ...state,
        achievements: upsertedAchievements,
      };
    case CREATE:
      return {
        ...state,
        achievements: [action.newAchievement, ...state.achievements],
      };
    case UPDATE:
      let updatedAchievements = state.achievements;
      for (let i = 0; i < updatedAchievements.length; i++) {
        if (
          updatedAchievements[i].details.id ===
          action.updatedAchievement.details.id
        ) {
          updatedAchievements[i] = action.updatedAchievement;
        }
      }
      return {
        ...state,
        achievements: updatedAchievements,
      };
    case UPDATE_CHILD:
      const parent = find(
        state.achievements,
        (o) => get(o, ['details', 'id'], null) === action.parentId
      );
      let updatedChildren = get(parent, ['children'], []);
      const child = find(
        updatedChildren,
        (o) => get(o, ['details', 'id'], null) === action.childId
      );
      updatedChildren = upsertArray(
        updatedChildren,
        child,
        ['details', 'id'],
        (elem) => ({
          ...child,
          myProgress: {
            ...get(child, ['myProgress'], {}),
            ...action.progressUpdates,
          },
        })
      );

      let upsertedAchievements2 = state.achievements;
      upsertedAchievements2 = upsertArray(
        upsertedAchievements2,
        parent,
        ['details', 'id'],
        (elem) => ({
          ...parent,
          children: updatedChildren,
        })
      );

      return {
        ...state,
        achievements: upsertedAchievements2,
      };
    case DELETE:
      let updatedAchievements2 = state.achievements;

      let deleteIndex = updatedAchievements2.findIndex(function (achievement) {
        return achievement.details.id === action.achievementId;
      });
      updatedAchievements2.splice(deleteIndex, 1);

      return {
        ...state,
        achievements: updatedAchievements2,
      };
    case RESET:
      return initialState;
    case REPLACE_ALL:
      return {
        ...state,
        achievements: action.updatedAchievements,
        moreReady: action.moreReady,
        lastUpdated: action.lastUpdated,
      };
    default:
      return state;
  }
};
export default achievementsReducer;
