import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import EditProgressFormik from './form';
import { ConfirmationMessage } from '../../../components/confirmationMessage';
import { ERROR } from '../../../constants';
import { createAchievement } from '../../../modules/achievements/actions';
import { updateGame } from '../../../modules/games/actions';
import { closeModal } from '../../../modules/userInterface/actions';

class EditProgressForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      messageType: ERROR,
      message: '',
    };
  }

  handleEditProgress = (values) => {
    const { newProgress } = values;
    const { updateProgress, closeModal } = this.props;

    closeModal();
    updateProgress(newProgress);
  };

  render() {
    const {
      achievementName,
      achievementDescription,
      maxQuantity,
      progress,
    } = this.props;
    const { loading, messageType, message } = this.state;

    return (
      <>
        {message && (
          <ConfirmationMessage type={messageType} message={message} />
        )}
        <h2>{achievementName}</h2>
        <p>{achievementDescription}</p>
        <br />
        <EditProgressFormik
          maxQuantity={maxQuantity}
          progress={progress}
          onSubmit={this.handleEditProgress}
          loading={loading}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  allGames: state.games,
});
const reduxActions = {
  closeModal,
  createAchievement,
  updateGame,
};
export default connect(
  mapStateToProps,
  reduxActions
)(withRouter(EditProgressForm));
