import { CREATE, UPDATE, DELETE, RESET, REPLACE_ALL } from './constants';

export const createTemplate = (newTemplate) => ({
  type: CREATE,
  newTemplate: newTemplate,
});

export const updateTemplate = (updatedTemplate) => ({
  type: UPDATE,
  updatedTemplate: updatedTemplate,
});

export const deleteTemplate = (templateId) => ({
  type: DELETE,
  templateId: templateId,
});

export const resetTemplates = () => ({
  type: RESET,
});

export const replaceAllTemplates = (
  updatedTemplates,
  moreReady,
  lastUpdated
) => ({
  type: REPLACE_ALL,
  updatedTemplates: updatedTemplates,
  moreReady: moreReady,
  lastUpdated: lastUpdated,
});
