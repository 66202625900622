import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import ReactGA from 'react-ga';
// import { CAT_DEV, ACT_CLICK } from '../../analyticsConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLockAlt,
  faTrophy,
  faPlusCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { CONTEXT_PAGE } from '../../constants';
import coreApi from '../../utils/coreApi';
import { upsertGames } from '../../modules/games/actions';
import { openModal } from '../../modules/userInterface/actions';
import { upsertAchievements } from '../../modules/achievements/actions';
import navigate from '../../utils/navigate';
import FlipMove from 'react-flip-move';
import Page from '../../components/page';
import { Button } from '../../components/button';
import isUserLoggedIn from '../../selectors/security/isUserLoggedIn';
import isUserAdmin from '../../selectors/security/isUserAdmin';
import getCurrentGameId from '../../selectors/context/getCurrentGameId';
import getCurrentGame from '../../selectors/context/getCurrentGame';
import { get, orderBy } from 'lodash';
import Achievement from '../../components/achievement';
import sortAchievements from '../../selectors/sort/sortAchievements';
import AddAchievementForm from './addAchievement';
import './index.scss';

class GamePage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
    };
  }

  componentDidMount = () => {
    this.animate();
    this.loadGameDetails();
    this.loadAchievements();
  };

  animate = () => {
    const that = this;
    setTimeout(function () {
      that.setState({ ready: true });
    }, 1);
  };

  loadGameDetails = () => {
    // load the details of this game from API
    const { upsertGames } = this.props;
    const currentGameId = getCurrentGameId(this.props);
    if (currentGameId) {
      const getArgs = { id: currentGameId };
      coreApi.get('/games', getArgs, true).then((apiResults) => {
        const { results } = apiResults;
        upsertGames(results);
      });
    }
  };

  loadAchievements = () => {
    const { upsertAchievements } = this.props;
    const currentGameId = getCurrentGameId(this.props);
    if (currentGameId) {
      // load list of this game's achievements from API
      const getArgs = { gameId: currentGameId, take: 100 };
      coreApi.get('/achievements', getArgs, true).then((apiResults) => {
        const { results } = apiResults;
        const sortedAchievements = sortAchievements(results);
        upsertAchievements(sortedAchievements);
      });
    }
  };

  handleAddAchievement = (achievement = null) => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      navigate(this.props, '/login');
    }
    if (loggedIn) {
      const { allGames, openModal } = this.props;
      const { games } = allGames;
      const game = getCurrentGame(this.props, games);
      const isPublic = get(game, ['gameDetails', 'public'], true);

      const editing = Boolean(achievement);
      const modalTitle = editing ? 'Edit achievement' : 'New achievement';

      openModal({
        title: modalTitle,
        content: (
          <AddAchievementForm
            editing={editing}
            achievement={achievement}
            publicGame={isPublic}
          />
        ),
      });
    }
  };

  render() {
    const { ready } = this.state;
    const { loggedIn, allGames, allAchievements } = this.props;

    // get game details
    const { games } = allGames;
    const currentGameId = getCurrentGameId(this.props);
    const game = getCurrentGame(this.props, games);
    const gameDetails = get(game, ['gameDetails'], {});
    const gameName = get(gameDetails, ['name'], 'Game');
    const gameAchievementCount = get(gameDetails, ['achievementCount'], 0);
    const gameAchievementsCompleted = get(
      game,
      ['myProgress', 'completedCount'],
      0
    );
    const isPublic = get(gameDetails, ['public'], true);

    // page description
    const pageDescription =
      gameAchievementCount === 0
        ? 'No achievements available yet'
        : loggedIn
        ? gameAchievementsCompleted +
          ' / ' +
          gameAchievementCount +
          ' achievements completed'
        : gameAchievementCount + ' achievements available';

    // get achievement list
    const achievements = get(allAchievements, ['achievements'], []);
    const filteredAchievements = achievements.filter(
      (achievement) =>
        currentGameId === get(achievement, ['details', 'gameId'], null)
    );

    // sort achievements by sort order
    const sortedAchievements = orderBy(filteredAchievements, ['sort'], ['asc']);

    // return page
    return (
      <Page id={'game-page'} context={CONTEXT_PAGE}>
        <FlipMove>
          {ready && (
            <div key={'intro'} className={'game-meta'}>
              <h1 aria-label={'Game: ' + gameName.toLowerCase()}>
                <FontAwesomeIcon icon={faTrophy} /> {gameName}
              </h1>
              {!isPublic && (
                <p className={'private'}>
                  <FontAwesomeIcon icon={faLockAlt} />
                  <b> PRIVATE GAME</b>
                </p>
              )}
              <p className={'page-description'}>{pageDescription}</p>
            </div>
          )}
          {ready && (
            <div key={1}>
              <Button
                text={'Add custom achievement'}
                onClick={() => this.handleAddAchievement()}
                buttonStyle={'cta'}
                icon={faPlusCircle}
              />
            </div>
          )}
        </FlipMove>
        <ul className={'list achievements'} aria-label={'Achievements'}>
          {sortedAchievements.length > 0 &&
            sortedAchievements.map((achievement) => {
              const achievementId = get(achievement, ['details', 'id'], 0);
              return (
                <Achievement
                  key={achievementId}
                  ready={ready}
                  achievement={achievement}
                  publicGame={isPublic}
                  handleEditAchievement={this.handleAddAchievement}
                />
              );
            })}
        </ul>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  loggedIn: isUserLoggedIn(state),
  userIsAdmin: isUserAdmin(state),
  allGames: state.games,
  allAchievements: state.achievements,
});
const reduxActions = {
  upsertGames,
  upsertAchievements,
  openModal,
};
export default connect(mapStateToProps, reduxActions)(withRouter(GamePage));
