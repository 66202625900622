import { SET, RESET } from './constants';

export const setUserAccessKeyTemp = (newUserAccessKeyTemp) => ({
  type: SET,
  newUserAccessKeyTemp: newUserAccessKeyTemp,
});

export const resetUserAccessKeyTemp = () => ({
  type: RESET,
});
