import { CREATE, UPDATE, DELETE, RESET, REPLACE_ALL } from './constants';

const initialState = {
  templates: [],
  moreReady: false,
  lastUpdated: '',
};

const templatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE:
      return {
        ...state,
        templates: [action.newTemplate, ...state.templates],
      };
    case UPDATE:
      let updatedTemplates = state.templates;
      for (let i = 0; i < updatedTemplates.length; i++) {
        if (updatedTemplates[i].id === action.updatedTemplate.id) {
          updatedTemplates[i] = action.updatedTemplate;
        }
      }
      return {
        ...state,
        templates: updatedTemplates,
      };
    case DELETE:
      let updatedTemplates2 = state.templates;

      let deleteIndex = updatedTemplates2.findIndex(function (template) {
        return template.id === action.templateId;
      });
      updatedTemplates2.splice(deleteIndex, 1);

      return {
        ...state,
        templates: updatedTemplates2,
      };
    case RESET:
      return initialState;
    case REPLACE_ALL:
      return {
        ...state,
        templates: action.updatedTemplates,
        moreReady: action.moreReady,
        lastUpdated: action.lastUpdated,
      };
    default:
      return state;
  }
};
export default templatesReducer;
