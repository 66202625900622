import { orderBy } from 'lodash';

// returns a freshly sorted list of achievements
// useful for async sorting - don't necessarily want to sort immediately
export default (achievements) => {
  // sort the achievements
  const sorted = orderBy(
    achievements,
    [
      'myProgress.started',
      'myProgress.completed',
      'myProgress.updated',
      'details.name',
    ],
    ['desc', 'asc', 'desc', 'asc']
  );

  // map sorted achievements with sort index
  const mapped = sorted.map((achievement, index) => {
    return {
      ...achievement,
      sort: index,
    };
  });

  // return freshly sorted array
  return mapped;
};
