import React from 'react';
import { connect } from 'react-redux';
import * as ReactModal from 'react-modal';
import { closeModal } from '../../modules/userInterface/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import './index.scss';

ReactModal.setAppElement('#root');

class Modal extends React.PureComponent {
  handleCloseModal = () => {
    const { closeModal } = this.props;
    closeModal();
  };

  render() {
    const { userInterface } = this.props;
    const { modal } = userInterface;
    const { title, content } = modal;

    return (
      <div className={'modal-wrapper'}>
        <ReactModal
          isOpen={Boolean(title)}
          onRequestClose={this.handleCloseModal}
          className={'modal animate'}
          overlayClassName={'modal-overlay animate'}
          contentLabel={title}
          closeTimeoutMS={200}
        >
          <h1>{title}</h1>
          <button
            onClick={this.handleCloseModal}
            className={'close-icon'}
            aria-label={'Click to Close Modal'}
          >
            <FontAwesomeIcon
              icon={faTimes}
              onClick={this.handleCloseModal}
              className={'animate'}
            />
          </button>
          {content}
        </ReactModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInterface: state.userInterface,
});
const reduxActions = {
  closeModal,
};
export default connect(mapStateToProps, reduxActions)(Modal);
