import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faCheck } from '@fortawesome/pro-regular-svg-icons';
import './index.scss';

export const Button = (props) => {
  const {
    text,
    buttonStyle,
    onClick,
    icon,
    loading,
    disabled,
    checked,
  } = props;

  let className = loading
    ? (buttonStyle || '') + ' loading'
    : buttonStyle || '';
  if (disabled) className += ' disabled';
  const clickAction = loading ? blankClick : onClick || blankClick;
  const buttonText = text || 'Continue';
  const maskedProps = {
    'aria-invalid': props['aria-invalid'],
    'aria-label': props['aria-label'],
    type: props.type,
  };

  return (
    <button
      {...maskedProps}
      className={className + ' animated slow'}
      onClick={clickAction}
      disabled={loading || disabled}
    >
      {className.includes('sprint') && (
        <>
          <div className={'decoration-1 animated slow'} />
          <div className={'decoration-2 animated slow'} />
        </>
      )}
      <div className={'button-content animated slow'}>
        {checked && (
          <div className={'check-decoration'}>
            <div className={'background'} />
            <FontAwesomeIcon icon={faCheck} />
          </div>
        )}
        {buttonText}
        {loading && <FontAwesomeIcon icon={faCircleNotch} spin />}
        {!loading && icon && <FontAwesomeIcon icon={icon} />}
      </div>
    </button>
  );
};

const blankClick = () => {
  // click action not found
};
