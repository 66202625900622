import { findIndex, get } from 'lodash';

// upserts the predicate into the array
// checks for matches using the comparePath
// 'getItem' should be a function that returns the desired output object
// returns the updated array (does not mutate the original)
export default (
  array = [],
  predicate = {},
  comparePath = [],
  getItem = () => {}
) => {
  const index = findIndex(array, function (o) {
    return get(o, comparePath, null) === get(predicate, comparePath, null);
  });
  return index === -1
    ? [...array, getItem()]
    : [
        ...array.slice(0, index),
        getItem(array[index]),
        ...array.slice(index + 1),
      ];
};
