import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { CAT_SECURITY, ACT_SUBMIT_FORM } from '../../../analyticsConstants';
import coreApi from '../../../utils/coreApi';
import { withRouter } from 'react-router-dom';
import navigate from '../../../utils/navigate';
import RegisterFormik from './form';
// import { Button } from '../../../components/button';
import { ConfirmationMessage } from '../../../components/confirmationMessage';
import { setUserAccessKey } from '../../../modules/userAccessKey/actions';
import { setUser } from '../../../modules/user/actions';
import { SUCCESS, ERROR } from '../../../constants';
import './index.scss';

class RegisterForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      messageType: ERROR,
      message: '',
    };
  }

  handleRegistrationAttempt = (values) => {
    const { username, password } = values;
    const { setUserAccessKey, setUser } = this.props;
    const { loading } = this.state;

    if (!loading) {
      this.setState({ loading: true });
      const that = this;

      coreApi
        .post(
          '/users',
          {
            username: username,
            chosenPassword: password,
          },
          false
        )
        .then((results) => {
          const { accessToken, userDetails } = results;

          // display success message
          that.setState({
            messageType: SUCCESS,
            message: 'Login successful!',
          });

          // dispatch analytics
          ReactGA.event({
            category: CAT_SECURITY,
            action: ACT_SUBMIT_FORM,
            label: 'registerNewAccount',
          });

          // save accessKey
          setUserAccessKey(accessToken);

          // save user details
          setUser(userDetails);

          // navigate to the dashboard
          setTimeout(function () {
            navigate(that.props, '/'); // TODO: change where registration redirects to
          }, 750);
        })
        .catch((error) => {
          // dispatch analytics
          ReactGA.event({
            category: CAT_SECURITY,
            action: ACT_SUBMIT_FORM,
            label: 'registerNewAccountFailed',
          });

          // display error message
          setTimeout(function () {
            that.setState({
              loading: false,
              message: 'Registration failed, please try again.',
            });
          }, 1000);
        });
    }
  };

  gotoLogin = () => {
    navigate(this.props, '/login');
  };

  render() {
    const { loading, messageType, message } = this.state;

    return (
      <>
        <h1>Sign Up for an anonymous account</h1>
        <p className={'instructions'}>
          Already have an account?{' '}
          <Link id={'login-link'} to={'/login'} className={'animated'}>
            Click here to login.
          </Link>
        </p>
        {message && (
          <ConfirmationMessage type={messageType} message={message} />
        )}
        <RegisterFormik
          onSubmit={this.handleRegistrationAttempt}
          loading={loading}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  template: state.template,
});
const reduxActions = {
  setUserAccessKey,
  setUser,
};
export default connect(mapStateToProps, reduxActions)(withRouter(RegisterForm));
