import React from 'react';
import { connect } from 'react-redux';
import Header from '../header';
import { Footer } from '../footer';
import Modal from '../modal';
import isUserAdmin from '../../selectors/security/isUserAdmin';
import { get } from 'lodash';
import ReactGA from 'react-ga';
import './index.scss';

class Page extends React.PureComponent {
  componentDidMount = () => {
    this.setAnalyticsUser();
  };

  componentDidUpdate = (prevProps) => {
    const prevUser = get(prevProps, ['user'], null);
    const currentUser = get(this.props, ['user'], null);
    if (prevUser !== currentUser) {
      this.setAnalyticsUser();
    }
  };

  setAnalyticsUser = () => {
    // update analytics userId (for customer-based tracking in Google Analytics)
    const { user } = this.props;
    const userId = get(user, ['id'], null);
    if (userId) {
      // if ReactGA has been initialized
      if (Boolean(ReactGA.ga())) {
        // then set the userId
        ReactGA.set({ userId: userId });
      }
    }
  };

  render() {
    const { id, userIsAdmin, children, className } = this.props;

    const roleClass = userIsAdmin ? 'admin' : '';
    const pageClass = 'page ' + roleClass + ' ' + className;

    return (
      <>
        <Header className={roleClass} />
        <Modal />

        <div id={id} className={pageClass}>
          <div className={'content'}>{children}</div>
        </div>

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  userIsAdmin: isUserAdmin(state),
});
const reduxActions = {};
export default connect(mapStateToProps, reduxActions)(Page);
