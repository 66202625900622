import React from 'react';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';

class ScrollToTop extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const prevLocation = get(prevProps, ['location'], null);
    const currentLocation = get(this.props, ['location'], null);
    if (prevLocation !== currentLocation) {
      // smooth scroll
      //   const body = document.querySelector('#root');
      //   body.scrollIntoView({ behavior: 'smooth' }, 500);

      // instant scroll
      if (typeof window !== 'undefined') {
        window.scrollTo(0, 0);
      }
    }
  }

  render() {
    return <React.Fragment />;
  }
}

export default withRouter(ScrollToTop);
