import React from 'react';
import { useFormik } from 'formik';
import { TextInput } from '../../../components/textInput';
// import { Checkbox } from '../../../components/checkbox';
import { Button } from '../../../components/button';
import { faUser, faLock } from '@fortawesome/pro-regular-svg-icons';
// import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';

export default (props) => {
  const { loading, onSubmit } = props;

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      rememberMe: true,
    },
    validationSchema: Yup.object({
      username: Yup.string().required('Required'),
      password: Yup.string().required('Required'),
    }),
    onSubmit: (values) => onSubmit(values),
  });

  const formClass = loading ? 'disabled' : '';

  return (
    <form className={formClass} onSubmit={formik.handleSubmit}>
      {/* <div className={'field-wrapper'}> */}
      {/* <div className={'border'} /> */}
      {/* <div className={'fields'}> */}
      <TextInput
        id={'username'}
        label={'Username'}
        icon={faUser}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        loading={loading}
        value={formik.values.username}
        error={formik.touched.username ? formik.errors.username : null}
        aria-invalid={Boolean(
          formik.touched.username && formik.errors.username
        )}
      />
      <TextInput
        id={'password'}
        label={'Password'}
        maskType={'password'}
        icon={faLock}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        loading={loading}
        value={formik.values.password}
        error={formik.touched.password ? formik.errors.password : null}
        aria-invalid={Boolean(
          formik.touched.password && formik.errors.password
        )}
      />
      <div className={'meta'}>
        {/* <Checkbox
              id={'rememberMe'}
              label={'Remember me'}
              onChange={formik.handleChange}
              checked={formik.values.rememberMe}
            /> */}
        {/* <Link
          to={'/password'}
          title={'Forgot your password'}
          className={'form-link'}
        >
          Forgot your password
        </Link> */}
        <div className={'clearfix'} />
        {/* </div> */}
        {/* </div> */}
      </div>

      <Button
        text={'Login'}
        type={'submit'}
        loading={loading}
        aria-invalid={!isEmpty(formik.errors)}
        aria-label={
          isEmpty(formik.errors) ? 'Login' : 'Please fix errors to login'
        }
      />
    </form>
  );
};
