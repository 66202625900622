import React from 'react';
import { connect } from 'react-redux';
import coreApi from '../../../utils/coreApi';
import { withRouter } from 'react-router-dom';
import AddGameFormik from './form';
import { ConfirmationMessage } from '../../../components/confirmationMessage';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { ERROR } from '../../../constants';
import {
  createGame,
  updateGame,
  deleteGame,
} from '../../../modules/games/actions';
import { closeModal } from '../../../modules/userInterface/actions';
import getCurrentGame from '../../../selectors/context/getCurrentGame';
import { Button } from '../../../components/button';
import { get } from 'lodash';

class AddGameForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      messageType: ERROR,
      message: '',
    };
  }

  handleAddGame = (values) => {
    const { user, closeModal, createGame } = this.props;
    const { name, publicGame } = values;

    this.setState({ loading: true });

    // prep payload
    const postData = {
      userId: get(user, ['id'], null),
      name: name,
      public: publicGame,
    };

    // save to api
    coreApi.post('/games', postData, true).then((newGame) => {
      // update redux
      createGame({
        gameDetails: {
          ...newGame,
        },
        myProgress: {
          completedCount: 0,
        },
      });

      // close modal
      closeModal();
    });
  };

  handleEditGame = (values) => {
    const { game, closeModal } = this.props;
    const { name, publicGame } = values;

    this.setState({ loading: true });

    // prep payload
    const putData = {
      id: get(game, ['id'], null),
      name: name,
      public: publicGame,
    };

    // save to api
    const that = this;
    coreApi.put('/games', putData, true).then((updatedGame) => {
      // update redux
      const { allGames, updateGame } = that.props;
      const games = get(allGames, ['games'], []);
      const oldGame = getCurrentGame(that.props, games);
      updateGame({
        ...oldGame,
        gameDetails: {
          ...updatedGame,
        },
      });

      // close modal
      closeModal();
    });
  };

  handleDeleteGame = () => {
    const { game, closeModal } = this.props;

    this.setState({ loading: true });

    // prep payload
    const putData = {
      id: get(game, ['id'], null),
      deleted: true,
    };

    // save to api
    const that = this;
    coreApi.put('/games', putData, false).then((updatedGame) => {
      // update redux
      const { deleteGame } = that.props;
      const gameId = get(updatedGame, ['id'], null);
      deleteGame(gameId);

      // close modal
      closeModal();
    });
  };

  render() {
    const { editing, game } = this.props;
    const { loading, messageType, message } = this.state;

    const formAction = editing ? this.handleEditGame : this.handleAddGame;
    const wasPublic = get(game, ['isPublic'], false);

    return (
      <>
        {message && (
          <ConfirmationMessage type={messageType} message={message} />
        )}
        <AddGameFormik
          editing={editing}
          game={game}
          onSubmit={formAction}
          loading={loading}
        />
        {editing && !wasPublic && (
          <Button
            text={'Delete Game'}
            onClick={this.handleDeleteGame}
            loading={loading}
            buttonStyle={'delete'}
            icon={faTrash}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  allGames: state.games,
});
const reduxActions = {
  closeModal,
  createGame,
  updateGame,
  deleteGame,
};
export default connect(mapStateToProps, reduxActions)(withRouter(AddGameForm));
