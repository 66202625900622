import {
  UPSERT,
  CREATE,
  UPDATE,
  UPDATE_CHILD,
  DELETE,
  RESET,
  REPLACE_ALL,
} from './constants';

export const upsertAchievements = (achievements) => ({
  type: UPSERT,
  achievements: achievements,
});

export const createAchievement = (newAchievement) => ({
  type: CREATE,
  newAchievement: newAchievement,
});

export const updateAchievement = (updatedAchievement) => ({
  type: UPDATE,
  updatedAchievement: updatedAchievement,
});

export const updateAchievementChild = (parentId, childId, progressUpdates) => ({
  type: UPDATE_CHILD,
  parentId: parentId,
  childId: childId,
  progressUpdates: progressUpdates,
});

export const deleteAchievement = (achievementId) => ({
  type: DELETE,
  achievementId: achievementId,
});

export const resetAchievements = () => ({
  type: RESET,
});

export const replaceAllAchievements = (
  updatedAchievements,
  moreReady,
  lastUpdated
) => ({
  type: REPLACE_ALL,
  updatedAchievements: updatedAchievements,
  moreReady: moreReady,
  lastUpdated: lastUpdated,
});
