import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import coreApi from '../../../utils/coreApi';
import navigate from '../../../utils/navigate';
import { createTemplate } from '../../../modules/000-TEMPLATE/actions';
import PasswordResetFormik from './form';
import { Button } from '../../../components/button';
import { ConfirmationMessage } from '../../../components/confirmationMessage';
import { SUCCESS, ERROR, INFO } from '../../../constants';
import getRouterParam from '../../../selectors/router/getRouterParam';
import './index.scss';

class PasswordResetForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      messageType: INFO,
      message: '',
      successful: false,
    };
  }

  handleResetAttempt = (values) => {
    const { loading } = this.state;

    if (!loading) {
      const { password } = values;
      this.setState({ loading: true });
      const that = this;

      const resetToken = getRouterParam(this.props, 'token');

      coreApi
        .post(
          '/auth/reset-password/save',
          {
            resetToken: resetToken,
            newPassword: password,
          },
          false
        )
        .then((successfulResult) => {
          if (Boolean(successfulResult)) {
            // display success message
            setTimeout(function () {
              that.setState({
                loading: false,
                messageType: SUCCESS,
                message: 'Success! Your password has been reset.',
                successful: true,
              });
            }, 1000);

            // navigate to the login page
            setTimeout(function () {
              navigate(that.props, '/login');
            }, 5000);
          } else {
            // display error message
            setTimeout(function () {
              that.setState({
                messageType: ERROR,
                loading: false,
                message: 'Password reset was unsuccessful, please try again.',
              });
            }, 1000);
          }
        })
        .catch((error) => {
          // display error message
          setTimeout(function () {
            that.setState({
              messageType: ERROR,
              loading: false,
              message: 'Password reset was unsuccessful, please try again.',
            });
          }, 1000);
        });
    }
  };

  gotoLogin = () => {
    navigate(this.props, '/login');
  };

  render() {
    const { loading, messageType, message, successful } = this.state;

    return (
      <>
        <h1>Choose a New Password</h1>
        <div className={'line'} />
        {message && (
          <ConfirmationMessage type={messageType} message={message} />
        )}
        {!successful && (
          <PasswordResetFormik
            onSubmit={this.handleResetAttempt}
            loading={loading}
          />
        )}
        {successful && (
          <>
            <p>New password, new you!</p>
            <p>
              We'll redirect you to the login page in a moment - use your new
              password to login!
            </p>
          </>
        )}
        <Button
          text={'Back to Login'}
          onClick={this.gotoLogin}
          buttonStyle={'outline'}
          loading={loading}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  template: state.template,
});
const reduxActions = {
  createTemplate,
};
export default connect(
  mapStateToProps,
  reduxActions
)(withRouter(PasswordResetForm));
