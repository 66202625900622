import React from 'react';
import { connect } from 'react-redux';
import isUserAdmin from '../../selectors/security/isUserAdmin';
import isUserLoggedIn from '../../selectors/security/isUserLoggedIn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencil,
  faLockAlt,
  faGameBoard,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faStar as faFullStar,
  faStarHalfAlt as faHalfStar,
} from '@fortawesome/pro-solid-svg-icons';
import FlipMove from 'react-flip-move';
import { Button } from '../button';
import { get } from 'lodash';
import './index.scss';

class Game extends React.PureComponent {
  handleViewAchievements = () => {
    const { gotoGame, game } = this.props;
    const { id } = game;
    if (id) {
      gotoGame(id);
    }
  };

  render() {
    const { handleEditGame, user, loggedIn, ready, game } = this.props;
    const {
      userId,
      name,
      quantity,
      started,
      completed,
      progress,
      isPublic,
    } = game;

    // card details
    const cardClass = started ? (completed ? 'completed' : 'started') : '';
    const cardLabelPrefix = started
      ? completed
        ? 'Completed '
        : 'Started '
      : '';
    const cardLabel = cardLabelPrefix + 'Game: ' + name;

    // user permissions
    const authorId = userId;
    const loggedInUserId = get(user, ['id'], null);
    const canEdit = loggedIn && authorId === loggedInUserId;

    // star icon
    const icon = started ? (completed ? faFullStar : faHalfStar) : faGameBoard;
    const iconLabel = started
      ? completed
        ? 'Completed!'
        : 'In progress'
      : 'Not started';

    // description
    const description =
      quantity === 0
        ? 'No achievements available yet'
        : loggedIn
        ? progress + ' / ' + quantity + ' achievements completed'
        : quantity + ' achievements available';

    // progress
    const parsedProgress = parseInt(progress, 10);
    const parsedQuantity = parseInt(quantity, 10);
    const progressString = completed
      ? '100%'
      : parsedProgress + ' / ' + parsedQuantity;
    const progressPercent = completed
      ? 100
      : Math.floor((parsedProgress / parsedQuantity) * 100);

    // return component
    return (
      <li className={'card game ' + cardClass} aria-label={cardLabel}>
        <FlipMove>
          {ready && (
            <div className={'card-content'} key={'content'}>
              <div className={'meta'}>
                <FontAwesomeIcon
                  className={'star'}
                  icon={icon}
                  aria-label={iconLabel}
                />
                <h3>
                  {name}{' '}
                  {completed && (
                    <span role={'img'} aria-label={'Congratulations!'}>
                      🎉
                    </span>
                  )}
                </h3>
                {started && (
                  <p className={'progress'} aria-label={'My progress'}>
                    {progressString}
                  </p>
                )}
              </div>
              <p className={'description'}>
                {!isPublic && (
                  <span className={'private'}>
                    <FontAwesomeIcon icon={faLockAlt} />
                    <b> PRIVATE: </b>
                  </span>
                )}
                {description}
                {canEdit && (
                  <Button
                    aria-label={'Click to edit this game'}
                    icon={faPencil}
                    text={'Edit Game'}
                    buttonStyle={'edit'}
                    onClick={() => handleEditGame(game)}
                  />
                )}
              </p>
              {!completed && (
                <div className={'buttons'}>
                  <Button
                    aria-label={
                      'Click to view the available achievements for "' +
                      name +
                      '".'
                    }
                    text={'achievements'}
                    onClick={this.handleViewAchievements}
                    buttonStyle={'start-tracking'}
                  />
                </div>
              )}
            </div>
          )}
          {ready && started && (
            <div
              className={'progress-bar'}
              aria-label={
                'Progress bar: ' + progressPercent + ' percent complete.'
              }
            >
              <div
                className={'progress animate'}
                style={{ width: progressPercent + '%' }}
              />
            </div>
          )}
        </FlipMove>
      </li>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  userIsAdmin: isUserAdmin(state),
  loggedIn: isUserLoggedIn(state),
});
const reduxActions = {};
export default connect(mapStateToProps, reduxActions)(Game);
