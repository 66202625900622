import { combineReducers, createStore, compose } from 'redux';
import { persistReducer } from 'redux-persist';
import { USER_LOGOUT } from './constants';
import storage from 'redux-persist/lib/storage';

// modules
import template from './modules/000-TEMPLATE';
import games from './modules/games';
import achievements from './modules/achievements';
import userInterface from './modules/userInterface';
import user from './modules/user';
import userAccessKey from './modules/userAccessKey';
import userAccessKeyTemp from './modules/userAccessKeyTemp';

// setup the main store modules
const appReducer = combineReducers({
  template,
  games,
  achievements,
  userInterface,
  user,
  userAccessKey,
  userAccessKeyTemp,
});

// setup the root reducer
const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

// setup persistConfig
const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['userAccessKeyTemp', 'userInterface'],
};

// persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// setup and export the store
const store = createStore(
  persistedReducer,
  compose(
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
);

export default store;
