import React from 'react';
import InputMask from 'react-input-mask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.scss';

const inputMasks = {
  phone: '(999) 999-9999',
  // email: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/g,
  zipcode: '99999',
};
const inputTypes = {
  password: 'password',
  number: 'number',
};

export class TextInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      localValue: '',
      focused: false,
    };
  }

  handleFocus = () => {
    this.setState({ focused: true });
  };

  handleBlur = (e) => {
    const { onBlur } = this.props;
    this.setState({ focused: false });
    if (onBlur) {
      onBlur(e);
    }
  };

  handleLocalChange = (e) => {
    this.setState({ localValue: e.target.value });
  };

  render() {
    const {
      id,
      classString,
      label,
      value,
      maskType,
      icon,
      error,
      onChange,
      loading,
    } = this.props;
    const { focused, localValue } = this.state;

    let wrapperClass = error
      ? 'text-input-wrapper error'
      : 'text-input-wrapper';
    if (classString) {
      wrapperClass += ' ' + classString;
    }
    const inputId = id;
    const changeAction = onChange || this.handleLocalChange;
    const inputValue = onChange ? value : localValue;
    const className = inputValue || focused ? 'animated above' : 'animated';
    const mask = maskType ? inputMasks[maskType] : null;
    const inputType = inputTypes[maskType] || 'text';
    const ariaLabel = error ? label + '. ' + error : label;
    const maskedProps = {
      'aria-invalid': this.props['aria-invalid'],
    };

    return (
      <div className={wrapperClass}>
        <label htmlFor={inputId} className={className}>
          {label}
        </label>
        <InputMask
          {...maskedProps}
          id={inputId}
          name={inputId}
          type={inputType}
          value={inputValue}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={changeAction}
          mask={mask}
          maskChar={' '}
          alwaysShowMask={false}
          aria-label={ariaLabel}
          disabled={loading}
        />
        {icon && <FontAwesomeIcon icon={icon} />}
        <p className={'validation-error'} role={'alert'}>
          {error}
        </p>
      </div>
    );
  }
}
