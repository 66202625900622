import React from 'react';
import { useFormik } from 'formik';
import { TextInput } from '../../../components/textInput';
import { Button } from '../../../components/button';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';

export default (props) => {
  const { loading, onSubmit } = props;

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
    }),
    onSubmit: (values) => onSubmit(values),
  });

  const formClass = loading ? 'disabled' : '';

  return (
    <form className={formClass} onSubmit={formik.handleSubmit}>
      <TextInput
        id={'email'}
        label={'Email address'}
        icon={faEnvelope}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        loading={loading}
        value={formik.values.email}
        error={formik.touched.email ? formik.errors.email : null}
        aria-invalid={Boolean(formik.touched.email && formik.errors.email)}
      />
      <Button
        text={'Reset Password'}
        type={'submit'}
        loading={loading}
        aria-invalid={!isEmpty(formik.errors)}
        aria-label={
          isEmpty(formik.errors)
            ? 'Reset Password'
            : 'Please fix errors to reset password'
        }
      />
    </form>
  );
};
