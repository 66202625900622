// constants for use with Google Analytics tracking

/* ----- CONFIG ----- */

// tracking setup
// TODO: insert correct tracking ID
// export const TRACKING_ID = 'UA-REPLACE_WITH_REAL_VALUE';
export const TRACKING_ID = 'G-DHLFJVB6Z4';

/* ----- EVENTS ----- */

// categories
export const CAT_DEV = 'dev'; // used for local dev testing
export const CAT_SECURITY = 'security';
export const CAT_ERROR = 'error';
export const CAT_INTERFACE = 'interface';
export const CAT_COURSE = 'course';
export const CAT_SPRINT = 'sprint';
export const CAT_RESOURCE = 'resource';
export const CAT_EXTERNAL_LINK = 'externalLink';

// actions
export const ACT_NAVIGATE = 'navigate';
export const ACT_CLICK = 'click';
export const ACT_START = 'start';
export const ACT_CONTINUE = 'continue';
export const ACT_COMPLETE = 'complete';
export const ACT_SUBMIT_FORM = 'submitForm';
export const ACT_TOGGLE_ACCOUNT_DRAWER = 'toggleAccountDrawer';
