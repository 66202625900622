import React from 'react';
import { useFormik } from 'formik';
import { TextInput } from '../../../components/textInput';
import { Button } from '../../../components/button';
import { faLock } from '@fortawesome/pro-regular-svg-icons';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';

export default (props) => {
  const { loading, onSubmit } = props;

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, 'Must be at least 8 characters')
        .required('Required'),
    }),
    onSubmit: (values) => onSubmit(values),
  });

  const formClass = loading ? 'disabled' : '';

  return (
    <form className={formClass} onSubmit={formik.handleSubmit}>
      <TextInput
        id={'password'}
        label={'New password'}
        maskType={'password'}
        icon={faLock}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        loading={loading}
        value={formik.values.password}
        error={formik.touched.password ? formik.errors.password : null}
        aria-invalid={Boolean(
          formik.touched.password && formik.errors.password
        )}
      />
      <Button
        text={'Save New Password'}
        type={'submit'}
        loading={loading}
        aria-invalid={!isEmpty(formik.errors)}
        aria-label={
          isEmpty(formik.errors)
            ? 'Save New Password'
            : 'Please fix errors to save new password'
        }
      />
    </form>
  );
};
