import { SET, RESET, UPDATE_FIELDS } from './constants';

export const setUser = (newUser) => ({
  type: SET,
  newUser: newUser,
});

export const resetUser = () => ({
  type: RESET,
});

export const updateUserFields = (fields) => ({
  type: UPDATE_FIELDS,
  fields: fields,
});
