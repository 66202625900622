import upsertArray from '../../utils/upsertArray';
import {
  UPSERT,
  CREATE,
  UPDATE,
  DELETE,
  RESET,
  REPLACE_ALL,
} from './constants';

const initialState = {
  games: [],
  moreReady: false,
  lastUpdated: '',
};

const gamesReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPSERT:
      let upsertedGames = state.games;
      action.games.forEach((game) => {
        upsertedGames = upsertArray(
          upsertedGames,
          game,
          ['gameDetails', 'id'],
          (elem) => ({
            ...game,
          })
        );
      });
      return {
        ...state,
        games: upsertedGames,
      };
    case CREATE:
      return {
        ...state,
        games: [action.newGame, ...state.games],
      };
    case UPDATE:
      let updatedGames = state.games;
      for (let i = 0; i < updatedGames.length; i++) {
        if (
          updatedGames[i].gameDetails.id === action.updatedGame.gameDetails.id
        ) {
          updatedGames[i] = action.updatedGame;
        }
      }
      return {
        ...state,
        games: updatedGames,
      };
    case DELETE:
      let updatedGames2 = state.games;

      let deleteIndex = updatedGames2.findIndex(function (game) {
        return game.gameDetails.id === action.gameId;
      });
      updatedGames2.splice(deleteIndex, 1);

      return {
        ...state,
        games: updatedGames2,
      };
    case RESET:
      return initialState;
    case REPLACE_ALL:
      return {
        ...state,
        games: action.updatedGames,
        moreReady: action.moreReady,
        lastUpdated: action.lastUpdated,
      };
    default:
      return state;
  }
};
export default gamesReducer;
