import { SET, RESET } from './constants';

const initialState = null;

const userAccessKeyTempReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET:
      return action.newUserAccessKeyTemp;
    case RESET:
      return initialState;
    default:
      return state;
  }
};
export default userAccessKeyTempReducer;
