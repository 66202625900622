import React from 'react';
import { connect } from 'react-redux';
import coreApi from '../../../utils/coreApi';
import { withRouter } from 'react-router-dom';
import AddAchievementFormik from './form';
import { ConfirmationMessage } from '../../../components/confirmationMessage';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { ERROR } from '../../../constants';
import {
  createAchievement,
  updateAchievement,
  deleteAchievement,
} from '../../../modules/achievements/actions';
import { updateGame } from '../../../modules/games/actions';
import { closeModal } from '../../../modules/userInterface/actions';
import getCurrentGame from '../../../selectors/context/getCurrentGame';
import getCurrentGameId from '../../../selectors/context/getCurrentGameId';
import { Button } from '../../../components/button';
import { get, filter } from 'lodash';

class AddAchievementForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      messageType: ERROR,
      message: '',
      children: [],
    };
  }

  componentDidMount = () => {
    this.setupExistingChildren();
  };

  setupExistingChildren = () => {
    const { achievement } = this.props;
    const existingChildren = get(achievement, ['children'], []);
    const children = existingChildren.map((child) => {
      const details = get(child, ['details']);
      return {
        id: get(details, ['id'], null),
        name: get(details, ['name'], null),
        quantity: get(details, ['quantity'], null),
        existing: true,
      };
    });
    this.setState({ children: children });
  };

  addChild = () => {
    // add a child achievement to the state array
    const childCount = this.state.children.length;
    const newChildren = [
      ...this.state.children,
      {
        id: 'temp-' + (childCount + 1),
        name: '',
        quantity: 1,
        existing: false,
      },
    ];
    this.setState({
      children: newChildren,
    });
  };
  updateChild = (childId, updates) => {
    // update child achievement in the state array
    const { children } = this.state;
    const newChildren = children.map((child) =>
      child.id === childId
        ? {
            ...child,
            ...updates,
          }
        : child
    );
    this.setState({ children: newChildren });
  };
  deleteChild = (childId) => {
    // delete child achievement from the state array
    const newChildren = filter(this.state.children, (o) => {
      return o.id !== childId;
    });
    this.setState({ children: newChildren });
  };

  handleAddAchievement = (values) => {
    const { user, closeModal, createAchievement } = this.props;
    const { children } = this.state;
    const {
      name,
      description,
      progressType,
      quantity,
      publicAchievement,
    } = values;
    const currentGameId = getCurrentGameId(this.props);

    this.setState({ loading: true });

    // prep payload
    const postData = {
      userId: get(user, ['id'], null),
      gameId: currentGameId,
      name: (name || '').trim(),
      description: (description || '').trim(),
      progressType,
      quantity,
      public: publicAchievement,
      children,
    };

    // save to api
    const that = this;
    coreApi.post('/achievements', postData, true).then((newAchievement) => {
      // update redux
      createAchievement(newAchievement);

      // update game redux
      const { allGames, updateGame } = that.props;
      const { games } = allGames;
      const currentGame = getCurrentGame(that.props, games);
      const currentCount = parseInt(
        get(currentGame, ['gameDetails', 'achievementCount'], 0),
        10
      );
      const newCount = currentCount + 1;
      updateGame({
        ...currentGame,
        gameDetails: {
          ...get(currentGame, ['gameDetails'], {}),
          achievementCount: newCount,
        },
      });

      // close modal
      closeModal();
    });
  };

  handleEditAchievement = (values) => {
    const { achievement, closeModal } = this.props;
    const { children } = this.state;
    const { name, description, quantity, publicAchievement } = values;

    this.setState({ loading: true });

    // prep payload
    const mappedChildren = children.map((child) => {
      return {
        ...child,
        id: child.existing ? child.id : null,
      };
    });
    const putData = {
      id: get(achievement, ['details', 'id'], null),
      name: name,
      description: description,
      quantity: quantity,
      public: publicAchievement,
      children: mappedChildren,
    };

    // save to api
    const that = this;
    coreApi.put('/achievements', putData, true).then((updatedAchievement) => {
      // update redux
      const { updateAchievement } = that.props;
      updateAchievement(updatedAchievement);

      // close modal
      closeModal();
    });
  };

  handleDeleteAchievement = () => {
    const { achievement, closeModal } = this.props;

    this.setState({ loading: true });

    // prep payload
    const putData = {
      id: get(achievement, ['details', 'id'], null),
      deleted: true,
    };

    // save to api
    const that = this;
    coreApi.put('/achievements', putData, true).then((updatedAchievement) => {
      // update redux
      const { deleteAchievement } = that.props;
      const achievementId = get(achievement, ['details', 'id'], null);
      deleteAchievement(achievementId);

      // update game redux
      const { allGames, updateGame } = that.props;
      const { games } = allGames;
      const currentGame = getCurrentGame(that.props, games);
      const currentCount = parseInt(
        get(currentGame, ['gameDetails', 'achievementCount'], 0),
        10
      );
      const newCount = currentCount - 1;
      updateGame({
        ...currentGame,
        gameDetails: {
          ...get(currentGame, ['gameDetails'], {}),
          achievementCount: newCount,
        },
      });

      // close modal
      closeModal();
    });
  };

  render() {
    const { addChild, updateChild, deleteChild } = this;
    const { editing, achievement, publicGame } = this.props;
    const { loading, messageType, message, children } = this.state;

    const formAction = editing
      ? this.handleEditAchievement
      : this.handleAddAchievement;
    const details = get(achievement, ['details'], {});
    const wasPublic = get(details, ['public'], false);

    return (
      <>
        {message && (
          <ConfirmationMessage type={messageType} message={message} />
        )}
        <AddAchievementFormik
          editing={editing}
          achievement={achievement}
          children={children}
          onSubmit={formAction}
          loading={loading}
          publicGame={publicGame}
          addChild={addChild}
          updateChild={updateChild}
          deleteChild={deleteChild}
        />
        {editing && !wasPublic && (
          <Button
            text={'Delete Achievement'}
            onClick={this.handleDeleteAchievement}
            loading={loading}
            buttonStyle={'delete'}
            icon={faTrash}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  allGames: state.games,
});
const reduxActions = {
  closeModal,
  updateGame,
  createAchievement,
  updateAchievement,
  deleteAchievement,
};
export default connect(
  mapStateToProps,
  reduxActions
)(withRouter(AddAchievementForm));
