import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/es/integration/react';
import { persistStore } from 'redux-persist';
import { Provider } from 'react-redux';
import store from './configureStore';
import WebFont from 'webfontloader';
import '@stripe/stripe-js';
import PageRouter from './pageRouter';
import './index.scss';

// load Google Fonts
WebFont.load({
  google: {
    families: ['Anton:400', 'Open+Sans:400,600,700', 'sans-serif'],
  },
});

// setup redux store
const persistor = persistStore(store);

ReactDOM.render(
  <PersistGate loading={null} persistor={persistor}>
    <Router>
      <Provider store={store}>
        <PageRouter />
      </Provider>
    </Router>
  </PersistGate>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
