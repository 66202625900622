import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link, NavLink } from 'react-router-dom';
import navigate from '../../utils/navigate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGameBoard,
  faTrophy,
  faSignOutAlt,
} from '@fortawesome/pro-regular-svg-icons';
import { toggleAccountDrawer } from '../../modules/userInterface/actions';
import isUserLoggedIn from '../../selectors/security/isUserLoggedIn';
import resetStore from '../../modules/resetStore';
import ReactGA from 'react-ga';
import { CAT_SECURITY, ACT_NAVIGATE } from '../../analyticsConstants';
import './index.scss';

class Header extends React.PureComponent {
  handleLogout = () => {
    // dispatch analytics
    ReactGA.event({
      category: CAT_SECURITY,
      action: ACT_NAVIGATE,
      label: 'logout',
    });

    // navigate to login page
    navigate(this.props, '/login');

    // logout
    resetStore();
  };

  render() {
    const { loggedIn } = this.props;

    return (
      <header>
        <div className={'header-content'}>
          <Link id={'logo'} to={'/'} className={'animated'}>
            TRACK YOUR GAMES!
            <span>Board Game Achievement Tracker</span>
          </Link>

          <nav role={'navigation'}>
            <NavLink
              to={'/'}
              className={'animated'}
              activeClassName={'active'}
              exact={true}
              aria-label={'Library'}
            >
              <span className={'desktop'}>Library</span>
              <FontAwesomeIcon icon={faGameBoard} />
            </NavLink>
            {loggedIn ? (
              <button
                className={'logout animated'}
                onClick={this.handleLogout}
                aria-label={'Logout'}
              >
                <span className={'desktop'}>Logout</span>
                <FontAwesomeIcon icon={faSignOutAlt} />
              </button>
            ) : (
              <NavLink
                to={'/login'}
                className={'animated'}
                activeClassName={'active'}
                aria-label={'Login'}
              >
                <span className={'desktop'}>Login</span>
                <FontAwesomeIcon icon={faTrophy} />
              </NavLink>
            )}
          </nav>

          <div className={'clearfix'} />
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  userInterface: state.userInterface,
  loggedIn: isUserLoggedIn(state),
});
const reduxActions = {
  toggleAccountDrawer,
};
export default connect(mapStateToProps, reduxActions)(withRouter(Header));
