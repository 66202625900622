import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import './index.scss';

export const Footer = (props) => {
  // const redditLink = 'https://www.reddit.com/r/TrackYourGames';
  const discordLink = 'https://discord.gg/TqCTzvnfKg';

  return (
    <footer>
      <div className={'footer-content'}>
        <div className={'column'}>
          <Link id={'footer-logo'} to={'/'} className={'animated'}>
            TRACK YOUR GAMES!
            <span>Board Game Achievement Tracker</span>
          </Link>
          <ul className={'social-icons'}>
            {/* <li>
              <a href={redditLink} title={'Reddit'}>
                <FontAwesomeIcon icon={faReddit} target={'_blank'} />
              </a>
            </li> */}
            <li>
              <a href={discordLink} title={'Discord'}>
                <FontAwesomeIcon icon={faDiscord} target={'_blank'} />
              </a>
            </li>
            <div className={'clearfix'} />
          </ul>
        </div>
        <div className={'column'}>
          <p>
            We're building a community on Discord and would love to hear your
            input!{' '}
            <span role={'img'} aria-label={'Grinning emoji'}>
              😄
            </span>
          </p>
          <p>
            <a
              className={'discord-link'}
              href={discordLink}
              title={'Discord link'}
            >
              Join our Discord server
            </a>
            {/* {' | '}
            <a
              className={'discord-link'}
              href={redditLink}
              title={'Reddit link'}
            >
              r/TrackYourGames
            </a> */}
          </p>
          <p>
            Welcome to the fun!{' '}
            <span role={'img'} aria-label={'Party popper emoji'}>
              🎉
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
};
