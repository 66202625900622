import { SET, RESET } from './constants';

const initialState = null;

const userAccessKeyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET:
      return action.newUserAccessKey;
    case RESET:
      return initialState;
    default:
      return state;
  }
};
export default userAccessKeyReducer;
