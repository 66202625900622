import React from 'react';
import { connect } from 'react-redux';
import Page from '../../components/page';
import FlipMove from 'react-flip-move';
import { withRouter } from 'react-router-dom';
import getRouterParam from '../../selectors/router/getRouterParam';
import { createTemplate } from '../../modules/000-TEMPLATE/actions';
import {
  LOGIN_FORM,
  REGISTER_FORM,
  PASSWORD_FORM,
  PASSWORD_RESET_FORM,
  BILLING_FORM,
  BILLING_SUCCESS,
  BILLING_FAIL,
} from './constants';
import './index.scss';
import LoginForm from './loginForm';
import RegisterForm from './registerForm';
import PasswordForm from './passwordForm';
import PasswordResetForm from './passwordResetForm';
import BillingForm from './billingForm';

class LoginPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
    };
  }

  componentDidMount = () => {
    const that = this;
    setTimeout(function () {
      that.setState({ ready: true });
    }, 1);
  };

  render() {
    const intent = getRouterParam(this.props, 'intent');
    const { form } = this.props;
    const { ready } = this.state;

    return (
      <Page id={'game-page'}>
        <div id={'login-page'}>
          <div className={'content-container'}>
            <div className={'form-container'}>
              <div className={'decoration'} />
              <div className={'form-wrapper animated'} role={'main'}>
                <FlipMove>
                  {form === LOGIN_FORM && ready && (
                    <div key={LOGIN_FORM}>
                      <LoginForm intent={intent} />
                    </div>
                  )}
                  {form === REGISTER_FORM && ready && (
                    <div key={REGISTER_FORM}>
                      <RegisterForm intent={intent} />
                    </div>
                  )}
                  {form === PASSWORD_FORM && ready && (
                    <div key={PASSWORD_FORM}>
                      <PasswordForm intent={intent} />
                    </div>
                  )}
                  {form === PASSWORD_RESET_FORM && ready && (
                    <div key={PASSWORD_RESET_FORM}>
                      <PasswordResetForm intent={intent} />
                    </div>
                  )}
                  {form === BILLING_FORM && ready && (
                    <div key={BILLING_FORM}>
                      <BillingForm intent={intent} />
                    </div>
                  )}
                  {form === BILLING_SUCCESS && ready && (
                    <div key={BILLING_SUCCESS}>
                      <BillingForm intent={intent} succeeded={true} />
                    </div>
                  )}
                  {form === BILLING_FAIL && ready && (
                    <div key={BILLING_FAIL}>
                      <BillingForm intent={intent} failed={true} />
                    </div>
                  )}
                </FlipMove>
              </div>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  template: state.template,
});
const reduxActions = {
  createTemplate,
};
export default connect(mapStateToProps, reduxActions)(withRouter(LoginPage));
