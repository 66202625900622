import {
  TOGGLE_ACCOUNT_DRAWER,
  OPEN_MODAL,
  CLOSE_MODAL,
  RESET,
} from './constants';

const initialState = {
  accountDrawerOpen: false,
  modal: {
    title: null,
    content: null,
  },
};

const interfacesReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_ACCOUNT_DRAWER:
      return {
        ...state,
        accountDrawerOpen: !state.accountDrawerOpen,
      };
    case OPEN_MODAL:
      return {
        ...state,
        modal: action.modalObject,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        modal: {
          title: null,
          content: null,
        },
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
};
export default interfacesReducer;
