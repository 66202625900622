import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.scss';

export class TextAreaInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      localValue: '',
      focused: false,
    };
  }

  handleFocus = () => {
    this.setState({ focused: true });
  };

  handleBlur = (e) => {
    const { onBlur } = this.props;
    this.setState({ focused: false });
    if (onBlur) {
      onBlur(e);
    }
  };

  handleLocalChange = (e) => {
    this.setState({ localValue: e.target.value });
  };

  render() {
    const { id, label, value, icon, error, onChange, loading } = this.props;
    const { focused, localValue } = this.state;

    const wrapperClass = error
      ? 'text-input-wrapper error'
      : 'text-input-wrapper';
    const inputId = id;
    const changeAction = onChange || this.handleLocalChange;
    const inputValue = onChange ? value : localValue;
    const className = inputValue || focused ? 'animated above' : 'animated';
    const ariaLabel = error ? label + '. ' + error : label;
    const maskedProps = {
      'aria-invalid': this.props['aria-invalid'],
    };

    return (
      <div className={wrapperClass}>
        <label htmlFor={inputId} className={className}>
          {label}
        </label>
        <textarea
          {...maskedProps}
          id={inputId}
          name={inputId}
          value={inputValue}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={changeAction}
          aria-label={ariaLabel}
          disabled={loading}
        />
        {icon && <FontAwesomeIcon icon={icon} />}
        <p className={'validation-error'} role={'alert'}>
          {error}
        </p>
      </div>
    );
  }
}
