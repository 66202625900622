import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import usePageTracking from './utils/pageTracking';
import ScrollToTop from './utils/scrollToTop';
import LoginPage from './pages/loginPage';
import {
  LOGIN_FORM,
  REGISTER_FORM,
  // PASSWORD_FORM,
  // PASSWORD_RESET_FORM,
  BILLING_FORM,
  // BILLING_SUCCESS,
  // BILLING_FAIL,
} from './pages/loginPage/constants';
import store from './configureStore';
import isUserLoggedIn from './selectors/security/isUserLoggedIn';
// import isUserBillingActive from './selectors/security/isUserBillingActive';
import ReactGA from 'react-ga';
import { CAT_SECURITY, ACT_NAVIGATE } from './analyticsConstants';
import resetStore from './modules/resetStore';
import AllGamesPage from './pages/allGamesPage';
import GamePage from './pages/gamePage';

export default function PageRouter() {
  // track page analytics
  usePageTracking();

  // return page routes
  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route
          path="/login/:intent+"
          render={(props) => <LoginPage {...props} form={LOGIN_FORM} />}
        />
        <Route
          path="/login"
          render={(props) => <LoginPage {...props} form={LOGIN_FORM} />}
        />
        <Route
          path="/register"
          render={(props) => <LoginPage {...props} form={REGISTER_FORM} />}
        />
        {/* <Route
          path="/password"
          render={(props) => <LoginPage {...props} form={PASSWORD_FORM} />}
        /> */}
        {/* <Route
          path="/reset-password/:token"
          render={(props) => (
            <LoginPage {...props} form={PASSWORD_RESET_FORM} />
          )}
        /> */}

        <Route path="/games/:gameId">
          <GamePage />
        </Route>
        <Route path="/games">
          <AllGamesPage />
        </Route>
        <Route exact path="/">
          <AllGamesPage />
        </Route>
        {/* <Route path="/achievements">
          <h1>MY ACHIEVEMENTS</h1>
        </Route> */}

        <PrivateRoute path="/" component={ProtectedRouter} />
      </Switch>
    </>
  );
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  const loggedIn = isUserLoggedIn(store.getState());
  if (!loggedIn) {
    // dispatch analytics
    ReactGA.event({
      category: CAT_SECURITY,
      action: ACT_NAVIGATE,
      label: 'unauthorized',
    });

    // redirect to login page
    return (
      <Route
        {...rest}
        render={(props) => {
          // log the user out
          resetStore();

          // redirect to login page
          return (
            <Redirect
              to={{
                pathname: '/login' + props.location.pathname,
                state: { from: props.location },
              }}
            />
          );
        }}
      />
    );
  }

  // const billingInfoActive = isUserBillingActive(store.getState());
  const billingInfoActive = true;
  if (!billingInfoActive) {
    // redirect to billing form
    return <Route {...rest} render={(props) => <BillingRouter {...props} />} />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

function BillingRouter() {
  return (
    <Switch>
      {/* <Route
        path="/checkout/success"
        render={(props) => <LoginPage {...props} form={BILLING_SUCCESS} />}
      />
      <Route
        path="/checkout"
        render={(props) => <LoginPage {...props} form={BILLING_FORM} />}
      /> */}
      <Route
        path="/"
        render={(props) => <LoginPage {...props} form={BILLING_FORM} />}
      />
    </Switch>
  );
}

function ProtectedRouter() {
  return (
    <Switch>
      {/* <Route path="/checkout/success">
        <LoginPage form={BILLING_SUCCESS} />
      </Route>
      <Route path="/checkout/cancel">
        <LoginPage form={BILLING_FAIL} />
      </Route>
      <Route path="/checkout">
        <LoginPage form={BILLING_FORM} />
      </Route> */}

      {/* <Route path="/profile">
        <h1>PROFILE SETTINGS</h1>
      </Route> */}
      <Route path="/">
        <AllGamesPage />
      </Route>
    </Switch>
  );
}
