import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/pro-regular-svg-icons';
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons';
import { get } from 'lodash';
import moment from 'moment';
import './index.scss';

export const AchievementChildren = (props) => {
  const { started, selectedChildId, selectChild, children } = props;

  // if not started, display nothing
  if (!started) return null;

  // if no children, display nothing
  if ((children || []).length === 0) return null;

  return (
    <div className={'achievement-children'}>
      {children.map((child) => {
        const details = get(child, ['details'], {});
        const myProgress = get(child, ['myProgress'], {});

        // details
        const id = get(details, ['id'], 0);
        const name = get(details, ['name'], '');
        const quantity = get(details, ['quantity'], 1);

        // progress
        const progressId = get(myProgress, ['id'], null);
        const started = get(myProgress, ['started'], false);
        const completed = get(myProgress, ['completed'], false);
        const progress = get(myProgress, ['progress'], 0);
        const updated = get(myProgress, ['updated'], null);
        const parsedProgress = parseInt(progress, 10);
        const parsedQuantity = parseInt(quantity, 10);
        const commaProgress = parsedProgress.toLocaleString();
        const commaQuantity = parsedQuantity.toLocaleString();

        // selection options
        const selectionDetails = {
          id: id,
          progressId: progressId,
          name: name,
          completed: completed,
          started: started,
          parsedProgress: parsedProgress,
          parsedQuantity: parsedQuantity,
        };

        // display
        let classString = started
          ? completed
            ? 'child completed'
            : 'child started'
          : 'child';
        const icon = completed ? faCheckSquare : faSquare;
        let progressString = commaProgress + ' / ' + commaQuantity;
        if (completed && updated) {
          progressString = moment(updated).format('MMM D, YYYY');
        }

        // updating progress
        const selected = selectedChildId === id;
        if (selected) {
          classString += ' selected';
        }

        return (
          <button
            key={id}
            className={classString}
            onClick={() => selectChild(id, selectionDetails)}
            disabled={completed}
          >
            <FontAwesomeIcon icon={icon} />
            <p className={'child-name'}>
              {name}
              <span className={'child-progress'}>{progressString}</span>
            </p>
          </button>
        );
      })}
    </div>
  );
};
