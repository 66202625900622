import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import ReactGA from 'react-ga';
// import { CAT_DEV, ACT_CLICK } from '../../analyticsConstants';
import { CONTEXT_PAGE } from '../../constants';
import coreApi from '../../utils/coreApi';
import { openModal } from '../../modules/userInterface/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGameBoard, faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import navigate from '../../utils/navigate';
import FlipMove from 'react-flip-move';
import Page from '../../components/page';
import { Button } from '../../components/button';
import isUserAdmin from '../../selectors/security/isUserAdmin';
import isUserLoggedIn from '../../selectors/security/isUserLoggedIn';
import { replaceAllGames } from '../../modules/games/actions';
import AddGameForm from './addGame';
import { get } from 'lodash';
import Game from '../../components/game';
import moment from 'moment';
import './index.scss';

class AllGamesPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
    };
  }

  componentDidMount = () => {
    this.animate();
    this.loadGames();
  };

  animate = () => {
    const that = this;
    setTimeout(function () {
      that.setState({ ready: true });
    }, 1);
  };

  loadGames = () => {
    // load list of games from API
    const { replaceAllGames } = this.props;
    const getArgs = { take: 100 };
    coreApi.get('/games', getArgs, true).then((apiResults) => {
      const { results, meta } = apiResults;
      const { moreAvailable } = meta;
      replaceAllGames(results, moreAvailable, moment());
    });
  };

  handleAddGame = (game = null) => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      navigate(this.props, '/login');
    }
    if (loggedIn) {
      const { openModal } = this.props;
      const editing = Boolean(game);
      const modalTitle = editing ? 'Edit game' : 'Add a game';

      openModal({
        title: modalTitle,
        content: <AddGameForm editing={editing} game={game} />,
      });
    }
  };

  gotoGame = (gameId) => {
    // navigate within the app
    navigate(this.props, '/games/' + gameId);
  };

  render() {
    const { ready } = this.state;
    const { allGames } = this.props;
    const games = get(allGames, ['games'], []);

    return (
      <Page id={'games-page'} context={CONTEXT_PAGE}>
        <FlipMove>
          {ready && (
            <div key={'intro'} className={'game-meta'}>
              <h1 aria-label={'All games'}>
                <FontAwesomeIcon icon={faGameBoard} /> Game Library
              </h1>
              <p className={'page-description'}>
                Here are the {games.length} games currently available for
                achievements tracking. You can add any other games you want to
                track with the "add a game" button!
              </p>
            </div>
          )}
          {ready && (
            <div key={1}>
              <Button
                text={'Add a game'}
                onClick={() => this.handleAddGame()}
                buttonStyle={'cta'}
                icon={faPlusCircle}
              />
            </div>
          )}
        </FlipMove>
        <ul className={'list games'} aria-label={'Games'}>
          {games.length > 0 &&
            games.map((game) => {
              const gameId = get(game, ['gameDetails', 'id'], 0);
              const userId = get(game, ['gameDetails', 'userId'], null);
              const name = get(game, ['gameDetails', 'name'], 0);
              const isPublic = get(game, ['gameDetails', 'public'], true);
              const achievementCount = get(
                game,
                ['gameDetails', 'achievementCount'],
                0
              );
              const completedCount = get(
                game,
                ['myProgress', 'completedCount'],
                0
              );

              return (
                <Game
                  key={gameId}
                  ready={ready}
                  game={{
                    id: gameId,
                    userId: userId,
                    name: name,
                    quantity: achievementCount,
                    progress: completedCount,
                    isPublic: isPublic,
                  }}
                  gotoGame={this.gotoGame}
                  handleEditGame={this.handleAddGame}
                />
              );
            })}
        </ul>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: isUserLoggedIn(state),
  user: state.user,
  userIsAdmin: isUserAdmin(state),
  allGames: state.games,
});
const reduxActions = {
  openModal,
  replaceAllGames,
};
export default connect(mapStateToProps, reduxActions)(withRouter(AllGamesPage));
