import React from 'react';
import './index.scss';

export const ProgressBar = (props) => {
  const { started, progressPercent } = props;

  if (!started) return null;

  return (
    <div
      className={'progress-bar'}
      aria-label={'Progress bar: ' + progressPercent + ' percent complete.'}
    >
      <div
        className={'progress animated slow'}
        style={{ width: progressPercent + '%' }}
      />
    </div>
  );
};
