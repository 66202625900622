import {
  UPSERT,
  CREATE,
  UPDATE,
  DELETE,
  RESET,
  REPLACE_ALL,
} from './constants';

export const upsertGames = (games) => ({
  type: UPSERT,
  games: games,
});

export const createGame = (newGame) => ({
  type: CREATE,
  newGame: newGame,
});

export const updateGame = (updatedGame) => ({
  type: UPDATE,
  updatedGame: updatedGame,
});

export const deleteGame = (gameId) => ({
  type: DELETE,
  gameId: gameId,
});

export const resetGames = () => ({
  type: RESET,
});

export const replaceAllGames = (updatedGames, moreReady, lastUpdated) => ({
  type: REPLACE_ALL,
  updatedGames: updatedGames,
  moreReady: moreReady,
  lastUpdated: lastUpdated,
});
