import axios from 'axios';
import { get } from 'lodash';
import resetStore from '../modules/resetStore';
import getAccessKey from '../selectors/security/getAccessKey';
import store from '../configureStore';

// setup api params
const apiUrl = 'https://api.trackyourgames.com';
// const apiUrl = 'http://localhost:8081';
const apiTimeout = 60000;
const apiDelay = 150;

// setup axios instance
const client = axios.create({
  baseURL: apiUrl,
  timeout: apiTimeout,
});

// main request function
const request = (method, url, data, logoutOnFail) => {
  // setup headers
  const headers = {
    'X-Client-Name': 'coreWeb',
    'X-Client-Api-Version': 2, // IMPORTANT: update this to match new server contracts
  };

  // add accessKey, if available
  const accessKey = getAccessKey(store.getState());
  if (accessKey) {
    headers['Authorization'] = 'Bearer ' + accessKey;
  }

  return new Promise((resolve, reject) => {
    (() => {
      switch (method) {
        case 'get':
          return client.request({
            url,
            method,
            params: data,
            headers: headers,
          });
        default:
          return client.request({
            url,
            method,
            data,
            headers: headers,
          });
      }
    })()
      .then((res) => {
        let wait = setTimeout(() => {
          clearTimeout(wait);
          resolve(res.data);
        }, apiDelay);
      })
      .catch((err) => {
        // an error occurred
        const statusCode = get(err, ['response', 'data', 'statusCode'], null);

        switch (statusCode) {
          case 412:
            // 412 PRECONDITION_FAILED - client api version is out-of-date
            // redirect the user to login page and refresh the page
            resetStore();
            if (typeof window !== 'undefined') {
              const currentPath = window.location.pathname;
              window.location.href = '/login' + currentPath;
              window.location.reload();
            }
            break;
          case 401:
            // 401 UNAUTHORIZED
            // redirect the user to login page and refresh the page
            if (logoutOnFail) {
              resetStore();
              if (typeof window !== 'undefined') {
                const currentPath = window.location.pathname;
                window.location.href = '/login' + currentPath;
                window.location.reload();
              }
            }
            break;
          case 402:
            // 402 PAYMENT REQUIRED
            // redirect the user to login page and refresh the page
            resetStore();
            if (typeof window !== 'undefined') {
              const currentPath = window.location.pathname;
              window.location.href = '/login' + currentPath;
              window.location.reload();
            }
            break;
          default:
            reject(err.response);
            break;
        }
        reject(err.response);
      });
  });
};

export default {
  get: (endpoint, data, logoutOnFail = false) =>
    request('get', endpoint, data, logoutOnFail),
  post: (endpoint, data, logoutOnFail = false) =>
    request('post', endpoint, data, logoutOnFail),
  put: (endpoint, data, logoutOnFail = false) =>
    request('put', endpoint, data, logoutOnFail),
  delete: (endpoint, data, logoutOnFail = false) =>
    request('delete', endpoint, data, logoutOnFail),
};
