import { SET, RESET } from './constants';

export const setUserAccessKey = (newUserAccessKey) => ({
  type: SET,
  newUserAccessKey: newUserAccessKey,
});

export const resetUserAccessKey = () => ({
  type: RESET,
});
