import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import {
  CAT_SECURITY,
  ACT_SUBMIT_FORM,
  ACT_NAVIGATE,
} from '../../../analyticsConstants';
import resetStore from '../../../modules/resetStore';
import coreApi from '../../../utils/coreApi';
import { withRouter } from 'react-router-dom';
import navigate from '../../../utils/navigate';
import { Button } from '../../../components/button';
import { ConfirmationMessage } from '../../../components/confirmationMessage';
import { setUserAccessKey } from '../../../modules/userAccessKey/actions';
import { setUser, updateUserFields } from '../../../modules/user/actions';
import { SUCCESS, ERROR } from '../../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faCreditCard,
  faSmile,
  faCircleNotch,
  faRedo,
} from '@fortawesome/pro-regular-svg-icons';
// import { loadStripe } from '@stripe/stripe-js';
import moment from 'moment';
import './index.scss';

// initialize stripe
// const stripePromise = loadStripe(STRIPE_API_KEY);

class BillingForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      loading: false,
      messageType: ERROR,
      message: '',
      verified: false,
      verificationFailed: true,
      gotoCheckoutError: false,
    };
  }

  componentDidMount = () => {
    const { succeeded } = this.props;
    const that = this;

    setTimeout(function () {
      that.setState({ ready: true });
    }, 3);

    if (succeeded) {
      setTimeout(function () {
        that.verifyBilling();
      }, 500);
    }
  };

  verifyBilling = () => {
    // hit our api to verify that stripe billing succeeded
    this.setState({ verified: false, verificationFailed: false });
    const that = this;
    coreApi
      .get('/billing/user-status', {}, false)
      .then((results) => {
        const { hasStripeId, paidAccessExpires } = results;
        if (hasStripeId && paidAccessExpires) {
          // update fields in local redux
          const { updateUserFields } = that.props;
          updateUserFields({
            hasStripeId: hasStripeId,
            paidAccessExpires: paidAccessExpires,
          });

          // check to see if user billing is now active
          if (moment().isBefore(moment(paidAccessExpires))) {
            // verification successful!
            that.setState({
              verified: true,
            });
          } else {
            that.handleVerificationError();
          }
        } else {
          that.handleVerificationError();
        }
      })
      .catch((error) => {
        that.handleVerificationError();
      });
  };

  handleVerificationError = () => {
    // dispatch analytics
    ReactGA.event({
      category: CAT_SECURITY,
      action: ACT_SUBMIT_FORM,
      label: 'verifyNewAccountFailed',
    });

    // failed - please logout and back in again
    const that = this;
    setTimeout(function () {
      that.setState({
        verified: true,
        verificationFailed: true,
      });
    }, 750);
  };

  gotoCheckout = () => {
    this.setState({ loading: true });

    // call api to get Stripe checkout sessionId
    // const that = this;
    // coreApi
    //   .post('/billing/create-checkout-session', {}, false)
    //   .then(async (results) => {
    //     const { sessionId } = results;

    //     // redirect user to Stripe checkout page
    //     const stripe = await stripePromise;
    //     const result = await stripe.redirectToCheckout({
    //       sessionId: sessionId,
    //     });

    //     if (result.error) {
    //       // TODO: handle error
    //       // If `redirectToCheckout` fails due to a browser or network
    //       // error, display the localized error message to your customer
    //       // using `result.error.message`.
    //       that.setState({ gotoCheckoutError: true, loading: false });
    //     }
    //   })
    //   .catch((error) => {
    //     // TODO: handle error
    //     that.setState({ gotoCheckoutError: true, loading: false });
    //   });
  };

  handleLogout = () => {
    // dispatch analytics
    ReactGA.event({
      category: CAT_SECURITY,
      action: ACT_NAVIGATE,
      label: 'logout',
    });

    // logout
    resetStore();

    // redirect to login page
    navigate(this.props, '/login');
  };

  gotoHomepage = () => {
    // navigate to homepage
    navigate(this.props, '/');
  };

  render() {
    const { succeeded, failed } = this.props;
    const {
      ready,
      loading,
      verified,
      verificationFailed,
      gotoCheckoutError,
    } = this.state;

    if (!ready) return null;

    if (succeeded && verified && verificationFailed) {
      return (
        <>
          <h1>Technical Difficulties</h1>
          <div className={'line'} />
          <ConfirmationMessage type={ERROR} message={'An error occurred'} />
          <p>
            Something went wrong with the billing verification. Please wait a
            moment and then try again with the button below.{' '}
            <b>You will only be billed once.</b>
          </p>
          <Button
            text={'Retry Verification'}
            icon={faRedo}
            onClick={this.verifyBilling}
            loading={loading}
          />
          <br />
          <br />
          <p>
            <i>
              Alternatively, try logging out and then signing back in again.
            </i>
          </p>
          <Button
            text={'Logout'}
            onClick={this.handleLogout}
            buttonStyle={'outline'}
            loading={loading}
          />
        </>
      );
    }

    if (succeeded && !verified)
      return (
        <>
          <h1>Verifying...</h1>
          <div className={'line'} />
          <p className={'verify-billing-spinner'}>
            <FontAwesomeIcon icon={faCircleNotch} spin />
          </p>
        </>
      );

    if (succeeded)
      return (
        <>
          <h1>Welcome to this app!</h1>
          <div className={'line'} />
          <ConfirmationMessage
            type={SUCCESS}
            message={'Account setup successfully!'}
          />
          <p>
            Welcome <FontAwesomeIcon icon={faSmile} />
          </p>
          <Button
            text={'Continue'}
            icon={faChevronRight}
            onClick={this.gotoHomepage}
            loading={loading}
          />
        </>
      );

    return (
      <>
        <h1>Monthly Subscription</h1>
        <div className={'line'} />
        {(failed || gotoCheckoutError) && (
          <>
            <ConfirmationMessage
              type={ERROR}
              message={'Billing not successful - please try again.'}
            />
            <p>The subscription price is $X/month.</p>
            <p>You can pause your subscription at any time.</p>
          </>
        )}
        {!failed && !gotoCheckoutError && (
          <>
            <p>The subscription price is $X/month.</p>
            <p>You can pause your subscription at any time.</p>
          </>
        )}
        <Button
          text={'Checkout'}
          icon={faCreditCard}
          onClick={this.gotoCheckout}
          loading={loading}
        />
        <Button
          text={'Logout'}
          onClick={this.handleLogout}
          buttonStyle={'outline'}
          loading={loading}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  template: state.template,
});
const reduxActions = {
  setUserAccessKey,
  setUser,
  updateUserFields,
};
export default connect(mapStateToProps, reduxActions)(withRouter(BillingForm));
