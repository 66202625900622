import React from 'react';
import { Button } from '../../button';
import './index.scss';

export const ProgressButtons = (props) => {
  const {
    completed,
    started,
    loading,
    disabled,
    updateProgress,
    editProgress,
    trackAchievement,
    achievementName,
    progress,
    quantity,
  } = props;

  // completed - don't display any buttons
  if (completed) return null;

  // child achievements with none selected - don't display
  if (disabled) return null;

  // not started - show "start tracking" button
  if (!started)
    return (
      <div className={'progress-buttons'}>
        <Button
          aria-label={
            'Click to start tracking the "' + achievementName + '" achievement.'
          }
          text={'Start tracking'}
          onClick={trackAchievement}
          buttonStyle={'start-tracking'}
          disabled={loading}
          loading={loading}
        />
      </div>
    );

  // currently tracking progress
  return (
    <div className={'progress-buttons'}>
      <Button
        aria-label={'Subtract one from progress.'}
        text={'-1'}
        onClick={() => updateProgress(progress - 1)}
        disabled={loading || progress === 0 || disabled}
      />
      <Button
        aria-label={'Update progress.'}
        text={'Edit Progress'}
        onClick={editProgress}
        disabled={loading || disabled}
        loading={loading}
      />
      <Button
        aria-label={'Add one to progress.'}
        text={'+1'}
        onClick={() => updateProgress(progress + 1)}
        disabled={loading || progress === quantity || disabled}
      />
    </div>
  );
};
