import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import './index.scss';

export class Checkbox extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      localChecked: true,
    };
  }

  handleLocalChange = (e) => {
    this.setState({ localChecked: e.target.checked });
  };

  render() {
    const { id, label, onChange, checked } = this.props;
    const { localChecked } = this.state;

    const inputId = id;
    const changeAction = onChange || this.handleLocalChange;
    const isChecked = onChange ? checked : localChecked;
    const wrapperClass = isChecked
      ? 'checkbox-input-wrapper checked'
      : 'checkbox-input-wrapper';
    const maskedProps = {
      'aria-invalid': this.props['aria-invalid'],
      'aria-label': this.props['aria-label'],
    };

    return (
      <div className={wrapperClass}>
        <input
          {...maskedProps}
          id={inputId}
          name={inputId}
          type={'checkbox'}
          checked={isChecked}
          onChange={changeAction}
        />
        <div className={'empty-checkbox-backdrop'} />
        <div className={'empty-checkbox animated'} />
        <FontAwesomeIcon icon={faCheck} className={'animated'} />
        <label htmlFor={inputId}>{label}</label>
        <div className={'clearfix'} />
      </div>
    );
  }
}
