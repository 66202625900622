import getCurrentGameId from './getCurrentGameId';
import { find, get } from 'lodash';

// returns the current course object for this page based on the route
export default (routerProps, games) => {
  const currentGameId = getCurrentGameId(routerProps);

  const currentGame = find(games, function (o) {
    return get(o, ['gameDetails', 'id'], 0) === currentGameId;
  });

  return currentGame;
};
