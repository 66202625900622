import React from 'react';
import { useFormik } from 'formik';
import { TextInput } from '../../../components/textInput';
import { Button } from '../../../components/button';
import { faTally } from '@fortawesome/pro-regular-svg-icons';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';

export default (props) => {
  const { maxQuantity, progress, loading, onSubmit } = props;

  const formik = useFormik({
    initialValues: {
      newProgress: progress || 0,
    },
    validationSchema: Yup.object({
      newProgress: Yup.number()
        .min(0, 'Must be greater than 0')
        .max(maxQuantity, 'Cannot exceed ' + maxQuantity)
        .required(),
    }),
    onSubmit: (values) => onSubmit(values),
  });

  const formClass = loading ? 'disabled' : '';

  return (
    <form className={formClass} onSubmit={formik.handleSubmit}>
      <TextInput
        id={'newProgress'}
        label={"What's your current progress?"}
        maskType={'number'}
        icon={faTally}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        loading={loading}
        value={formik.values.newProgress}
        error={formik.touched.newProgress ? formik.errors.newProgress : null}
        aria-invalid={Boolean(
          formik.touched.newProgress && formik.errors.newProgress
        )}
      />

      <Button
        text={'Update progress'}
        type={'submit'}
        loading={loading}
        aria-invalid={!isEmpty(formik.errors)}
        aria-label={
          isEmpty(formik.errors)
            ? 'Update progress'
            : 'Please fix the errors to update your progress'
        }
      />
    </form>
  );
};
