import {
  TOGGLE_ACCOUNT_DRAWER,
  OPEN_MODAL,
  CLOSE_MODAL,
  RESET,
} from './constants';

export const toggleAccountDrawer = () => ({
  type: TOGGLE_ACCOUNT_DRAWER,
});

// modalObject: { title: '', content: </> }
export const openModal = (modalObject) => ({
  type: OPEN_MODAL,
  modalObject: modalObject,
});

export const closeModal = () => ({
  type: CLOSE_MODAL,
});

export const resetUserInterface = () => ({
  type: RESET,
});
