import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faLockAlt } from '@fortawesome/pro-regular-svg-icons';
import { Button } from '../../button';
import './index.scss';

export const AchievementDetails = (props) => {
  const {
    icon,
    iconLabel,
    progressString,
    achievementName,
    isPublic,
    description,
    canEdit,
    editAchievement,
    achievement,
  } = props;

  return (
    <>
      <div className={'meta'}>
        <p className={'indicator'}>
          <FontAwesomeIcon className={'star'} icon={icon} />
          <span>{iconLabel}</span>
        </p>
        <p className={'progress'} aria-label={'My progress'}>
          {progressString}
        </p>
        <div className={'clearfix'} />
      </div>
      <h3>{achievementName}</h3>
      <p className={'description'}>
        {!isPublic && (
          <span className={'private'}>
            <FontAwesomeIcon icon={faLockAlt} />
            <b> PRIVATE: </b>
          </span>
        )}
        {description}
        {canEdit && (
          <Button
            aria-label={'Click to edit this achievement'}
            icon={faPencil}
            text={'Edit Achievement'}
            buttonStyle={'edit'}
            onClick={() => editAchievement(achievement)}
          />
        )}
      </p>
    </>
  );
};
