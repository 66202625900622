import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import navigate from '../../utils/navigate';
import coreApi from '../../utils/coreApi';
import isUserLoggedIn from '../../selectors/security/isUserLoggedIn';
import isUserAdmin from '../../selectors/security/isUserAdmin';
import {
  updateAchievement,
  updateAchievementChild,
} from '../../modules/achievements/actions';
import { updateGame } from '../../modules/games/actions';
import { faStar as faBlankStar } from '@fortawesome/pro-regular-svg-icons';
import {
  faStar as faFullStar,
  faStarHalfAlt as faHalfStar,
} from '@fortawesome/pro-solid-svg-icons';
import { openModal } from '../../modules/userInterface/actions';
import FlipMove from 'react-flip-move';
import getCurrentGameId from '../../selectors/context/getCurrentGameId';
import getCurrentGame from '../../selectors/context/getCurrentGame';
import EditProgressForm from './editProgress';
import { AchievementDetails } from './achievementDetails';
import { AchievementChildren } from './achievementChildren';
import { ProgressButtons } from './progressButtons';
import { ProgressBar } from './progressBar';
import { get } from 'lodash';
import moment from 'moment';
import './index.scss';

const initialSelectedDetails = {
  id: null,
  progressId: null,
  name: '',
  completed: false,
  started: false,
  parsedProgress: 0,
  parsedQuantity: 0,
};

class Achievement extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedChildId: null,
      selectedDetails: initialSelectedDetails,
    };
  }

  handleTrackAchievement = () => {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      navigate(this.props, '/login');
    }
    if (loggedIn) {
      this.saveTrackAchievement();
    }
  };

  handleSelectChild = (childId, selectedDetails) => {
    this.setState({
      selectedChildId: childId,
      selectedDetails: selectedDetails,
    });
  };

  saveTrackAchievement = () => {
    const {
      achievement,
      user,
      updateAchievement,
      updateAchievementChild,
    } = this.props;
    const { selectedChildId } = this.state;
    const currentGameId = getCurrentGameId(this.props);
    const parentId = get(achievement, ['details', 'id'], null);

    const that = this;
    this.setState({ loading: true });

    // prep payload
    const postData = {
      userId: get(user, ['id'], null),
      gameId: currentGameId,
      achievementId: selectedChildId || parentId,
    };

    // save to api
    coreApi
      .post('/userAchievements', postData, false)
      .then((newUserAchievement) => {
        // update redux
        const newProgressId = get(newUserAchievement, ['id'], null);

        if (!selectedChildId) {
          // update parent achievement
          updateAchievement({
            ...achievement,
            myProgress: {
              ...get(achievement, ['myProgress'], {}),
              id: newProgressId,
              started: true,
            },
          });
        } else {
          // update child achievement
          updateAchievementChild(parentId, selectedChildId, {
            id: newProgressId,
            started: true,
          });

          // updated selected child details
          that.setState({
            selectedDetails: {
              ...that.state.selectedDetails,
              progressId: newProgressId,
              started: true,
            },
          });
        }

        // update state
        that.setState({ loading: false });
      });
  };

  handleEditProgress = () => {
    const { achievement, openModal } = this.props;
    const { selectedChildId, selectedDetails } = this.state;

    // parent achievement
    const name = get(achievement, ['details', 'name'], '');
    const description = get(achievement, ['details', 'description'], '');
    const maxQuantity = get(achievement, ['details', 'quantity'], 1);
    const progress = get(achievement, ['myProgress', 'progress'], 0);

    // child achievement
    const childName = get(selectedDetails, ['name'], '');
    const childDescription = get(selectedDetails, ['description'], '');
    const childMaxQuantity = get(selectedDetails, ['parsedQuantity'], 1);
    const childProgress = get(selectedDetails, ['parsedProgress'], 0);

    // selected achievement
    const thisName = selectedChildId ? childName : name;
    const thisDescription = selectedChildId ? childDescription : description;
    const thisMaxQuantity = selectedChildId ? childMaxQuantity : maxQuantity;
    const thisProgress = selectedChildId ? childProgress : progress;

    // open modal
    openModal({
      title: 'Edit progress',
      content: (
        <EditProgressForm
          achievementName={thisName}
          achievementDescription={thisDescription}
          maxQuantity={thisMaxQuantity}
          progress={thisProgress}
          updateProgress={this.updateProgress}
        />
      ),
    });
  };

  updateProgress = (newProgress) => {
    const {
      achievement,
      updateAchievement,
      updateAchievementChild,
    } = this.props;
    const { selectedChildId, selectedDetails } = this.state;

    // calculations
    const parentAchievementId = get(achievement, ['details', 'id'], null);
    const parentProgressId = get(achievement, ['myProgress', 'id'], null);
    const parentMaxQuantity = parseInt(
      get(achievement, ['details', 'quantity'], 1),
      10
    );
    const childProgressId = get(selectedDetails, ['progressId'], null);
    const childMaxQuantity = get(selectedDetails, ['parsedQuantity'], 1);

    const thisMaxQuantity = selectedChildId
      ? childMaxQuantity
      : parentMaxQuantity;
    const newCompleted = newProgress >= thisMaxQuantity;
    const thisProgressId = selectedChildId ? childProgressId : parentProgressId;

    // update the progress
    if (thisProgressId) {
      const that = this;
      this.setState({ loading: true });

      // payload
      const putData = {
        id: thisProgressId,
        progress: newProgress,
        completed: newCompleted,
      };

      // save to api
      coreApi
        .put('/userAchievements', putData, false)
        .then((updatedUserAchievement) => {
          // update redux
          if (!selectedChildId) {
            // parent progress
            updateAchievement({
              ...achievement,
              myProgress: {
                ...get(achievement, ['myProgress'], {}),
                progress: newProgress,
                completed: newCompleted,
                updated: get(updatedUserAchievement, ['updated'], null),
              },
            });
          } else {
            // child progress
            let newSelectedChildId = selectedChildId;
            let updatedSelectedDetails = {
              ...that.state.selectedDetails,
              parsedProgress: newProgress,
              completed: newCompleted,
            };

            // if child completed, update parent progress
            if (newCompleted) {
              const parentOldProgress = get(
                achievement,
                ['myProgress', 'progress'],
                0
              );
              const parentNewProgress = parentOldProgress + 1;
              const parentCompleted = parentNewProgress >= parentMaxQuantity;

              updateAchievement({
                ...achievement,
                myProgress: {
                  ...get(achievement, ['myProgress'], {}),
                  progress: parentNewProgress,
                  completed: parentCompleted,
                  updated: get(updatedUserAchievement, ['updated'], null),
                },
              });

              newSelectedChildId = null;
              updatedSelectedDetails = initialSelectedDetails;
            }

            // update child progress
            updateAchievementChild(parentAchievementId, selectedChildId, {
              progress: newProgress,
              completed: newCompleted,
              updated: get(updatedUserAchievement, ['updated'], null),
            });

            // updated selected child details
            that.setState({
              selectedChildId: newSelectedChildId,
              selectedDetails: updatedSelectedDetails,
            });
          }

          // update redux game progress
          if (!selectedChildId && newCompleted) {
            // parent progress
            const { allGames, updateGame } = that.props;
            const { games } = allGames;
            const currentGame = getCurrentGame(that.props, games);
            const completedCount = parseInt(
              get(currentGame, ['myProgress', 'completedCount'], 0),
              10
            );
            const newCompletedCount = completedCount + 1;
            updateGame({
              ...currentGame,
              myProgress: {
                ...get(currentGame, ['myProgress'], {}),
                completedCount: newCompletedCount,
              },
            });
          }

          // child progress
          // TODO: update this

          // update state
          that.setState({ loading: false });
        });
    }
  };

  render() {
    const {
      handleEditAchievement,
      ready,
      achievement,
      publicGame,
      loggedIn,
      user,
    } = this.props;
    const { selectedChildId, selectedDetails, loading } = this.state;
    const details = get(achievement, ['details'], {});
    const myProgress = get(achievement, ['myProgress'], {});
    const children = get(achievement, ['children'], []);

    // details
    // const achievementId = get(details, ['id'], 0);
    const name = get(details, ['name'], '');
    const description = get(details, ['description'], '');
    const quantity = get(details, ['quantity'], 1);
    const isPublic = publicGame && get(details, ['public'], true);

    // user permissions
    const authorId = get(details, ['userId'], null);
    const loggedInUserId = get(user, ['id'], null);
    const canEdit = loggedIn && authorId === loggedInUserId;

    // progress
    const started = get(myProgress, ['started'], false);
    const completed = get(myProgress, ['completed'], false);
    const progress = get(myProgress, ['progress'], 0);
    const updated = get(myProgress, ['updated'], null);

    // card details
    const cardClass = started ? (completed ? 'completed' : 'started') : '';
    const cardLabelPrefix = started
      ? completed
        ? 'Completed '
        : 'Started '
      : '';
    const cardLabel = cardLabelPrefix + 'Achievement: ' + name;

    // star icon
    const icon = started ? (completed ? faFullStar : faHalfStar) : faBlankStar;
    const iconLabel = started
      ? completed
        ? 'Completed!'
        : 'Tracking'
      : 'Available';

    // progress
    const parsedProgress = parseInt(progress, 10);
    const parsedQuantity = parseInt(quantity, 10);
    const commaProgress = parsedProgress.toLocaleString();
    const commaQuantity = parsedQuantity.toLocaleString();
    let progressString = commaProgress + ' / ' + commaQuantity;
    if (completed && updated) {
      progressString = moment(updated).format('MMM D, YYYY');
    }
    const progressPercent = completed
      ? 100
      : Math.max(1, Math.floor((parsedProgress / parsedQuantity) * 100));

    // selected child
    const hasChildren = children.length > 0;
    // const childId = get(selectedDetails, ['id'], null);
    const childName = get(selectedDetails, ['name'], '');
    const childCompleted = get(selectedDetails, ['completed'], false);
    const childStarted = get(selectedDetails, ['started'], false);
    const childProgress = get(selectedDetails, ['parsedProgress'], 0);
    const childQuantity = get(selectedDetails, ['parsedQuantity'], 1);

    // progressButton progress
    const disableProgressButtons =
      hasChildren && started && selectedChildId === null;
    const thisName = hasChildren ? childName : name;
    const thisCompleted = hasChildren ? childCompleted : completed;
    const thisStarted = hasChildren ? childStarted : started;
    const thisProgress = hasChildren ? childProgress : parsedProgress;
    const thisQuantity = hasChildren ? childQuantity : parsedQuantity;

    // return component
    return (
      <li className={'card achievement ' + cardClass} aria-label={cardLabel}>
        <FlipMove>
          {ready && (
            <div key={'achievement-content'}>
              <div className={'card-content'} key={'content'}>
                <AchievementDetails
                  icon={icon}
                  iconLabel={iconLabel}
                  progressString={progressString}
                  achievementName={name}
                  isPublic={isPublic}
                  description={description}
                  canEdit={canEdit}
                  editAchievement={handleEditAchievement}
                  achievement={achievement}
                />
                <AchievementChildren
                  selectedChildId={selectedChildId}
                  selectChild={this.handleSelectChild}
                  started={started}
                  children={children}
                />
                <ProgressButtons
                  completed={thisCompleted}
                  started={thisStarted}
                  loading={loading}
                  disabled={disableProgressButtons}
                  updateProgress={this.updateProgress}
                  editProgress={this.handleEditProgress}
                  trackAchievement={this.handleTrackAchievement}
                  achievementName={thisName}
                  progress={thisProgress}
                  quantity={thisQuantity}
                />
              </div>
              <ProgressBar
                started={started}
                progressPercent={progressPercent}
              />
            </div>
          )}
        </FlipMove>
      </li>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  loggedIn: isUserLoggedIn(state),
  userIsAdmin: isUserAdmin(state),
  allGames: state.games,
});
const reduxActions = {
  updateAchievement,
  updateAchievementChild,
  updateGame,
  openModal,
};
export default connect(mapStateToProps, reduxActions)(withRouter(Achievement));
