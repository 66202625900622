import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import coreApi from '../../../utils/coreApi';
import navigate from '../../../utils/navigate';
import { createTemplate } from '../../../modules/000-TEMPLATE/actions';
import PasswordFormik from './form';
import { Button } from '../../../components/button';
import { ConfirmationMessage } from '../../../components/confirmationMessage';
import { SUCCESS, ERROR, INFO } from '../../../constants';
import './index.scss';

class PasswordForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      messageType: INFO,
      message: '',
      successful: false,
    };
  }

  handleResetAttempt = (values) => {
    const { loading } = this.state;

    if (!loading) {
      const { email } = values;
      this.setState({ loading: true });
      const that = this;

      coreApi
        .post(
          '/auth/reset-password',
          {
            username: email,
          },
          false
        )
        .then((results) => {
          // display success message
          setTimeout(function () {
            that.setState({
              loading: false,
              messageType: SUCCESS,
              message: 'Success!',
              successful: true,
            });
          }, 1000);
        })
        .catch((error) => {
          // display error message
          setTimeout(function () {
            that.setState({
              messageType: ERROR,
              loading: false,
              message: 'Password reset was unsuccessful, please try again.',
            });
          }, 1000);
        });
    }
  };

  gotoLogin = () => {
    navigate(this.props, '/login');
  };

  render() {
    const { loading, messageType, message, successful } = this.state;

    return (
      <>
        <h1>Reset Password</h1>
        <div className={'line'} />
        {message && (
          <ConfirmationMessage type={messageType} message={message} />
        )}
        {!successful && (
          <PasswordFormik
            onSubmit={this.handleResetAttempt}
            loading={loading}
          />
        )}
        {successful && (
          <>
            <p>We just emailed you a link to finish resetting your password.</p>
            <p>
              Check your inbox in a couple minutes for the email. See you on the
              other side!{' '}
              <span role="img" aria-label="wink emoji">
                😉
              </span>
            </p>
          </>
        )}
        <Button
          text={'Back to Login'}
          onClick={this.gotoLogin}
          buttonStyle={'outline'}
          loading={loading}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  template: state.template,
});
const reduxActions = {
  createTemplate,
};
export default connect(mapStateToProps, reduxActions)(withRouter(PasswordForm));
