import React from 'react';
import { useFormik } from 'formik';
import { TextAreaInput } from '../../../components/textAreaInput';
import { TextInput } from '../../../components/textInput';
import { SelectInput } from '../../../components/selectInput';
import { Button } from '../../../components/button';
import { Checkbox } from '../../../components/checkbox';
import { ChildrenEditor } from './childrenEditor';
import {
  faTrophy,
  faCommentAltLines,
  faTally,
} from '@fortawesome/pro-regular-svg-icons';
import * as Yup from 'yup';
import { get, isEmpty } from 'lodash';

const progressTypes = [
  { value: 'quantity', label: 'Number counter' },
  { value: 'umbrella', label: 'Checklist of sub-achievements' },
];
const fullProgressTypes = {
  quantity: { value: 'quantity', label: 'Number counter' },
  umbrella: {
    value: 'umbrella',
    label: 'Checklist of sub-achievements',
  },
};

export default (props) => {
  const {
    editing,
    achievement,
    children,
    loading,
    onSubmit,
    publicGame,
    addChild,
    updateChild,
    deleteChild,
  } = props;
  const details = get(achievement, ['details'], {});
  const wasPublic = get(details, ['public'], false);

  const formik = useFormik({
    initialValues: {
      name: get(details, ['name'], ''),
      description: get(details, ['description'], ''),
      progressType: get(details, ['progressType'], 'quantity'),
      quantity: get(details, ['quantity'], 1),
      publicAchievement: wasPublic,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, 'Must be at least 3 characters')
        .required('Required'),
      description: Yup.string()
        .min(3, 'Must be at least 3 characters')
        .required('Required'),
      quantity: Yup.number().min(1, 'Must be greater than 1').required(),
      publicAchievement: Yup.boolean(),
    }),
    onSubmit: (values) => onSubmit(values),
  });

  const formClass = loading ? 'disabled' : '';
  const buttonMessage = editing ? 'Update Achievement' : 'Add New Achievement';

  return (
    <form className={formClass} onSubmit={formik.handleSubmit}>
      <TextInput
        id={'name'}
        label={'Achievement name'}
        icon={faTrophy}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        loading={loading}
        value={formik.values.name}
        error={formik.touched.name ? formik.errors.name : null}
        aria-invalid={Boolean(formik.touched.name && formik.errors.name)}
      />
      <TextAreaInput
        id={'description'}
        label={'Achievement description'}
        icon={faCommentAltLines}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        loading={loading}
        value={formik.values.description}
        error={formik.touched.description ? formik.errors.description : null}
        aria-invalid={Boolean(
          formik.touched.description && formik.errors.description
        )}
      />

      {!editing && (
        <SelectInput
          id={'progressType'}
          label={'Track progress with:'}
          onChange={formik.setFieldValue}
          onBlur={formik.handleBlur}
          loading={loading}
          value={fullProgressTypes[formik.values.progressType]}
          options={progressTypes}
          error={
            formik.touched.progressType ? formik.errors.progressType : null
          }
          aria-invalid={Boolean(
            formik.touched.progressType && formik.errors.progressType
          )}
        />
      )}

      {formik.values.progressType === 'quantity' && (
        <TextInput
          id={'quantity'}
          label={'Amount needed to complete'}
          maskType={'number'}
          icon={faTally}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          loading={loading}
          value={formik.values.quantity}
          error={formik.touched.quantity ? formik.errors.quantity : null}
          aria-invalid={Boolean(
            formik.touched.quantity && formik.errors.quantity
          )}
        />
      )}

      {formik.values.progressType === 'umbrella' && (
        <ChildrenEditor
          loading={loading}
          children={children}
          addChild={addChild}
          updateChild={updateChild}
          deleteChild={deleteChild}
        />
      )}

      {publicGame && !wasPublic && (
        <Checkbox
          id={'publicAchievement'}
          label={'PUBLIC (lets other users track this achievement)'}
          onChange={formik.handleChange}
          checked={formik.values.publicAchievement}
        />
      )}

      <Button
        text={buttonMessage}
        type={'submit'}
        loading={loading}
        aria-invalid={!isEmpty(formik.errors)}
        aria-label={
          isEmpty(formik.errors)
            ? buttonMessage
            : 'Please fix the errors to ' + buttonMessage
        }
      />
    </form>
  );
};
