// stripe API key
// TODO: replace with real Stripe API key
// export const STRIPE_API_KEY = 'pk_live_EfwzsqxBiuJteMOncmcJ';
export const STRIPE_API_KEY = null;

// global/root redux action types
export const USER_LOGOUT = 'USER_LOGOUT';

// cookies/local storage
export const USER_TOKEN_COOKIE = '';
export const USER_TOKEN_LOCAL_STORAGE = '';

// common enums
export const INFO = 'INFO';
export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';

// admin bar contexts
export const CONTEXT_PAGE = 'PAGE';

// user roles
export const ROLE_SUPER_ADMIN = 'superAdmin';
export const ROLE_USER = 'user';
